import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from '../../../common/Loader';
import { useForm, Controller } from 'react-hook-form';
import { getToken, triggerAlert, handleTableRowClick, formattedDateTime, pageReload, get_user_menu_permission } from '../../../utils';
import Pagination from "../../PaginationComponent";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { menuIdData } from '../../../utils/constants';
import DataTable from 'react-data-table-component';

import { Menu, MenuItem } from "@mui/material";

const CategoryList = () => {

    const api_base_url = process.env.REACT_APP_API_BASE_URL;
    const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const token = getToken();
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryDrpList, setCategoryDrpList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [showEditForm, setShowEditForm] = useState(false);
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const mainHeading = 'Tickets Categories';
    const heading = `Supports / Tickets Categories`;
    const pageSize = 50;

    // Menu Permissions
    const [addPermission, setAddPermission] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [selectedidAction, setselectedidAction] = useState('')
    const [selectedidRow, setselectedidRow] = useState({})
    //   const [exportPermission, setExportPermission] = useState(false);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event,row) => {
        setAnchorEl(event.currentTarget);
        setselectedidAction(row.predefined_id)
        setselectedidRow(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };




    const [customerlist, setcustomerlist] = useState(false)
    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Sub Category',
            selector: row => row.ticket_category_name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => formattedDateTime(row.updated_at),
            sortable: true,
        },
        
        {
            name: 'Action',
            selector: row => row.is_deleted,
            sortable: true,
            cell: row => (
                <>
                {/* <div className="btn-group float-end col-md-1 ml-10">
                    <button
                        type="button"
                        className="btn btn-primary w-md btn-md dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ marginLeft: "10px" }}
                    >
                        Action <i className="mdi mdi-chevron-down"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                    {editPermission && <div
                            className="dropdown-item hand_icon"
                            onClick={() => { setSelectedRow(row); handleEditForm(row); }}
                        >
                            Edit
                        </div>}
                        {deletePermission && <div
                            className="dropdown-item hand_icon"
                            onClick={() => deleteCategory(row)}
                        >
                            Delete
                        </div>}
                    </div>
                </div> */}


{
                            <div>
                                <div
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event)=>handleClick(event,row)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-primary w-md btn-md dropdown-toggle"
                                        // data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ "marginLeft": "10px" }}
                                    >
                                        Action
                                        <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                </div>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        sx: {
                                            boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                            border: '1px solid #e0e0e0', // Custom border color
                                            outline: 'none', // Remove the black border,        
                                            minWidth: '132px'

                                        },
                                        style: {
                                            border: '1px solid #fff', // Ensure border is applied
                                        }
                                    }}
                                >
                                    
                                    {editPermission &&  <MenuItem onClick={() => { setSelectedRow(selectedidRow); handleEditForm(selectedidRow); }}>
                                            Edit
                                        </MenuItem>}

                                       {deletePermission && <MenuItem onClick={() => deleteCategory(selectedidRow)}>
                                            Delete
                                        </MenuItem>
                                       }
                                </Menu>
                            </div>
                            }



                </>
            ),
        }

    ];
    const fetchCategoriesList = async (page=currentPage, size = perPage) => {
        try {
            setIsLoading(true);
            const ticketsCategoryList = await axios.get(`/apiV1/ticket_categories/?page_size=${pageSize}&page_number=${pageNum}`);
            const responseStatus = ticketsCategoryList.status;
            if (responseStatus == 200) {
                const itemsArray = ticketsCategoryList.data.results.data;
                setCategoryList(itemsArray);
                setCurrentPage(page);
                setPerPage(size);
                const total_pages = ticketsCategoryList.data.results.pagination.total_items                ;
                setTotalPageCount(total_pages);
                setIsLoading(false);
                setTotalRows(total_pages);
            } else {
                setIsLoading(false);
                throw new Error("Something went wrong..");

            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert("error", err.message);
            setTotalRows(0);
        }
    }

    const fetchCategoryList = async () => {
        try {
            setIsLoading(true);
            const ticketsCategoryList = await axios.get(`/apiV1/get_ticket_parent_category/`);
            const responseStatus = ticketsCategoryList.status;
            if (responseStatus == 200) {
                const itemsArray = ticketsCategoryList.data.results;
                setCategoryDrpList(itemsArray);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error("Something went wrong..");
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert("error", err.message);
        }
    }

    const deleteCategory = (row) => {
        handleClose()
        if (row) {
            Swal.fire({
                icon: 'warning',
                title: 'Do you want to delete data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var id = selectedRowId;
                    try {
                        const response = await axios.get(`/apiV1/supports/delete_ticket_sub_category?ticket_category_id=${row.id}`);
                        const response_data = response.data;
                        if (response_data.error_code == "200") {
                            triggerAlert('success', 'success', response_data.message);
                            //fetchCategoriesList();
                            pageReload();
                        } else {
                            triggerAlert('error', 'Oops...', 'Something went wrong..');
                        }
                    } catch (error) {
                        if (error.response.data.message) {
                            triggerAlert('error', 'Oops...', error.response.data.message);
                            }
                            else
                            {
                                triggerAlert('error', 'Oops...', 'Something went wrong..');
                            }
                    }
                }
            })
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    };

    const formSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'id': selectedRow.ticket_category_id,
                'ticket_category_name': data.sub_category,
                'ticket_parent_category': data.category
            };
            const response = await axios.put(`/apiV1/supports/update_ticket_sub_category/${selectedRow.id}/update`, params);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Category Updated Successfully!!!`);
                setShowEditForm(false);
                setIsLoading(false);
                //fetchCategoriesList();
                pageReload();
            } else {
                setIsLoading(false);
                throw new Error('Unable to Updated Category Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', err.message);
        }
    };


    const handleEditForm = (selectedRow) => {
        handleClose()
        if (selectedRow) {
            console.log(selectedRow);
            setShowEditForm(true);
            setValue("category", selectedRow.ticket_parent_category);
            setValue("sub_category", selectedRow.ticket_category_name);
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    }

    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage);
        //  console.log(selectedPage);
        setPageNum(selectedPage + 1);
        fetchCategoriesList(selectedPage + 1);
    };

    let props = {
        pageCount: totalPageCount,
        handlePageClick: handlePageClick,
        selectedPage: pageNum - 1
    }

    useEffect(() => {
        if (showEditForm) {
            fetchCategoryList();
        } else {
            fetchCategoriesList();
        }
    }, [showEditForm, pageNum]);

    // useEffect(() => {
    //     const menu_id = menuIdData?.ticket_categories;
    //     setAddPermission(get_user_menu_permission(menu_id, 'add'));
    //     setEditPermission(get_user_menu_permission(menu_id, 'edit'));
    //     setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
    //     // setExportPermission(get_user_menu_permission(menu_id, 'export'));
    // }, []);
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handlePageChange = page => {
        fetchCategoriesList(page);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCategoriesList(page, newPerPage);
        setPerPage(newPerPage);
    };
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    console.log(access_privileges,'access_privileges');
    useEffect(() => {
        fetchCategoriesList(1);
        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Tickets Categories" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Tickets Categories" && subaccess.permissions.indexOf(2) !== -1) {
                    setAddPermission(true)
                }
                if (subaccess.name == "Tickets Categories" && subaccess.permissions.indexOf(3) !== -1) {
                    setEditPermission(true)
                }
                if (subaccess.name == "Tickets Categories" && subaccess.permissions.indexOf(4) !== -1) {
                    setDeletePermission(true)
                }

            })


        })
    }, []);
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <div className="row mt-5">
                                <div className="col-12">
                                    {!showEditForm ? (
                                        <>
                                            <div className="card">
                                                 {addPermission &&
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-11">
                                                        </div>

                                                        <div className="col-md-1">
                                                            <Link to="/support/create_categories" className="btn btn-primary"><i class="bx bx-user"></i> Create</Link>
                                                        </div>


                                                    </div>
                                                </div>
                                               }
                                                <div className="card-body">
                                                    <DataTable
                                                        columns={columns}
                                                        data={categoryList}
                                                        pagination
                                                        paginationServer
                                                        fixedHeaderScrollHeight='600px'
                                                        fixedHeader
                                                        persistTableHead
                                                        paginationTotalRows={totalRows}
                                                        paginationComponentOptions={paginationComponentOptions}
                                                        paginationPerPage={perPage}
                                                        paginationDefaultPage={currentPage}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                    />

                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div>
                                                                <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label" for="formrow-lname-input">Category <span style={{ color: 'red' }}>*</span></label>
                                                                                <select className="form-select" {...register('category', { required: 'Category is required' })} >
                                                                                    <option value="">Please Select</option>
                                                                                    {categoryDrpList && categoryDrpList.map((list, index) => (
                                                                                        <option value={list.id
                                                                                        } selected={selectedRow.ticket_parent_category == list.id}>{list.parent_cat_name
                                                                                            }</option>
                                                                                    ))}
                                                                                </select>
                                                                                {errors.category && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.category.message}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="mb-3">
                                                                                <label className="form-label" for="formrow-name-input">Sub Category <span style={{ color: 'red' }}>*</span></label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="sub_category"
                                                                                    id="formrow-name-input"
                                                                                    className="form-control"
                                                                                    placeholder="Enter your Sub Category"
                                                                                    {...register('sub_category', {
                                                                                        required: 'Sub Category is required',
                                                                                    })}
                                                                                    defaultValue={selectedRow.ticket_category_name || ''}
                                                                                />
                                                                                {/* <input type="text" name="sub_category" id="formrow-name-input" className="form-control" placeholder="Enter your Subject" {...register('sub_category', {
                                                                                        required: 'Sub Category is required',
                                                                                    })} value={selectedRow.ticket_category_name || ''} /> */}
                                                                                {errors.sub_category && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.sub_category.message}</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                                        <button className="btn btn-warning w-md" type="button" onClick={() => setShowEditForm(false)}>Cancel</button>
                                                                        <button className="btn btn-primary w-md me-md-2" type="submit">Update</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}
            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </div>
    );
}

export default CategoryList;