import React, { useEffect, useState } from "react";
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../../pages/PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert } from '../../utils';
import Loader from '../../common/Loader'

import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { Menu, MenuItem } from "@mui/material";

export default function PlansList() {
    const mainHeading = "Plan List";
    const heading = "Settings / Plan List";
    const [isLoading, setIsLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [SuccessAlert, setSuccessAlert] = useState(false);
    const [CreatenewUser, setCreatenewUser] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [editid, seteditid] = useState('')
    const [selectedid, setselectedid] = useState('')
    const [selectedidData, setselectedidData] = useState({})
    const [searchQuery, setSearchQuery] = useState('');
    const [textbase, settextbase] = useState("Create")
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, } = useForm();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event,row) => {
        setAnchorEl(event.currentTarget);
        setselectedid(row.id)
        setselectedidData(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        if (CreatenewUser) {
            // Call handleClose function when modal is opened
            handleClose();
        }
    }, [CreatenewUser]);


    const columns = [
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.Num, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                        <span>{row.Num}</span>
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Plan Name</label>, sortable: true, selector: row => row.plan_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.plan_name}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Currency</label>, sortable: true, selector: row => row.currency, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.currency}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Total Credits</label>, sortable: true, selector: row => row.total_credits, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.total_credits}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Amount</label>, sortable: true, selector: row => row.amount, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.amount}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Description</label>, sortable: true, selector: row => row.description, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.description}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    {row.is_deleted ? (
                        <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">Inactive</span>

                    ) : row.is_deleted == false ? (
                        <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                    ) : (
                        <span className="btn btn-info btn-sm btn-rounded waves-effect waves-light">Unknown</span>
                    )}
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    {/* {customeredit || customerdelete ?
                        <div className="btn-group float-end col-md-1  ml-10">
                            <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ "marginLeft": "10px" }}>
                                Action <i className="mdi mdi-chevron-down"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end  ">
                                {customeredit && <div className="dropdown-item hand_icon" onClick={() => { seteditid(row.id); settextbase("Update"); setCreatenewUser(true); onloadeditedData(row); }}>Edit</div>}
                                {customerdelete && <div className="dropdown-item hand_icon" onClick={() => DeletePlan(!row.is_deleted, row.id)}  >Delete  </div>}
                            </div>
                        </div> : ""} */}


                    {customeredit || customerdelete ?
                        <div>
                            <div
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event)=>handleClick(event,row)}
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary w-md btn-md dropdown-toggle"
                                    // data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{ "marginLeft": "10px" }}
                                >
                                    Action
                                    <i className="mdi mdi-chevron-down"></i>
                                </button>
                            </div>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    sx: {
                                        boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                        border: '1px solid #e0e0e0', // Custom border color
                                        outline: 'none', // Remove the black border,        
                                        minWidth: '132px'
    
                                    },
                                    style: {
                                        border: '1px solid #fff', // Ensure border is applied
                                    }
                                }}
                                 >
                                {
                                    customeredit &&
                                    <MenuItem onClick={() => { seteditid(selectedid); settextbase("Update"); setCreatenewUser(true); onloadeditedData(selectedidData); }}>
                                        Edit
                                    </MenuItem>
                                }
                                {
                                    customerdelete &&
                                    <MenuItem onClick={() => DeletePlan(!row.is_deleted, selectedid)} >
                                        Delete
                                    </MenuItem>
                                }
                            </Menu>
                        </div>
                        : ""}


                </>,
        },


    ];
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handleKeySearch = (e) => {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchListData(1, perPage, searchkey);
    };
    const onloadeditedData = (data) => {
        setValueForm("plan_name", data.plan_name)
        setValueForm("currency", data.currency)
        setValueForm("amount", data.amount)
        setValueForm("total_credits", data.total_credits)
        setValueForm("description", data.description)
        setValueForm("subscription_type", data.subscription_type)
        setValueForm("plan_type", data.plan_type)
        setValueForm("no_of_users", data.no_of_users)
        setValueForm("no_of_tests", data.no_of_tests)
        setValueForm("cycle_start_day", data.cycle_start_day)
        setValueForm("cycle_end_day", data.cycle_end_day)
        setValueForm("unit_price", data.unit_price)
        setValueForm("no_of_resumes", data.no_of_resumes)
        setValueForm("preceder_plan", data.preceder_plan)
        if(data.perks.length !== 0){
            let subsc = []
            data.perks.map((perk, i)=>{
                let objet = {
                    "name":perk
                }
                subsc.push(objet)
            })

            setperkslist([...subsc])
        }
        
    }
    const {
        register: registerAdd,
        formState: { errors },
    } = useForm();
    const fetchListData = async (page, size = perPage, searchkey = searchQuery) => {
        setIsLoading(true);
        let offcet = parseInt(page) - 1
        try {
            let token =sessionStorage.getItem("admin_user_token")
            const response = await axios.get(`apiV1/add-subscription-plan/?limit=${size}&offset=${(page - 1) * 10}${searchkey ? `&search=${searchkey}` : ""}`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
            const response_data = response.data
            console.log(response_data);
            if (response.status === 200) {
                const itemsArray = response_data.results;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item
                })
                const total_pages = response_data.count;
                setTotalRows(total_pages);
                setListData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }
            else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }

        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
        }
    };



    const DeletePlan = (status, id) => {
        handleClose()
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {

            try {
                const response = await axios.delete(`apiV1/add-subscription-plan/${id}/`);
                const response_data = response.data;
                if (response_data.status_code === 200) {
                    triggerAlert('success', 'success', 'Status updated Successfully!!');
                    fetchListData(1);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Plan Already Assigned to user..');
            }
        })

    };
    const onSubmit = (data) => {
        data.plan_level = '1';
        const adminId = sessionStorage.getItem('admin_id');
        let perk = [];
        perkslist.map((item, i)=>{
            if(item.name){
                perk.push(item.name)
            }
        })
        const handleSuccess = (message) => {
            fetchListData(1);
            setCreatenewUser(false);
            resetForm();
            triggerAlert('success', 'Success', message);
        };
        
        const handleError = (error) => {
            if (error.response && error.response.data && error.response.data.message) {
                triggerAlert('error', 'Oops...', error.response.data.message);
            } else {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
        };
        if(perk.length !== 0){
            data.perks = perk
        }
        console.log(data)
        if (editid) {
            data.updated_by = adminId;
            axios.patch(`apiV1/add-subscription-plan/${editid}/`, data)
                .then((response) => {
                    handleSuccess('Data updated successfully');
                })
                .catch(handleError);
        } else {
            
            data.created_by = adminId;
            axios.post("apiV1/add-subscription-plan/", data)
                .then((response) => {
                    if (response.data.status_code === 200 && response.data.message === 'Subscription plan already exists') {
                        triggerAlert('error', 'Oops...', 'Subscription plan already exists');
                    } else {
                        handleSuccess('Data added successfully');
                    }
                })
                .catch(handleError);
        }
    };
    


    const handlePageChange = page => {
        fetchListData(page);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    const [customerlist, setcustomerlist] = useState(false)
    const [customeradd, setcustomeradd] = useState(false)
    const [customeredit, setcustomeredit] = useState(false)
    const [customerdelete, setcustomerdelete] = useState(false)
    const [perkslist, setperkslist] = useState([{"name":""}])
    const addPerkstoList = () => {
        let perkslists = perkslist;
        perkslists.push({name:""})

        console.log(perkslists)
        setperkslist([...perkslists])
    }
    const removePerksFromlist = (id) => {
        let perkslists = perkslist;
        perkslists.splice(id, 1)
        setperkslist([...perkslists])

    }
    const changevalueperkslist = (value, i) => {
        let perkslists = perkslist;
        perkslists.map((item,k)=>{
            if(k == i ){
                item.name = value
            }

            return item
        })

        setperkslist([...perkslists])
    }
    useEffect(() => {
        fetchListData(1);
        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(2) !== -1) {
                    setcustomeradd(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(3) !== -1) {
                    setcustomeredit(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(4) !== -1) {
                    setcustomerdelete(true)
                }

            })


        })
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                {customeradd &&
                                                    <div className="btn-group float-end col-md-1  ml-10">
                                                        <button type="button" className="btn btn-primary w-md btn-md" onClick={() => { setCreatenewUser(true); settextbase("Create"); seteditid(); resetForm(); }} style={{ "marginLeft": "10px" }}>
                                                            Create Plan
                                                        </button>

                                                    </div>}

                                            </div>
                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isLoading ?
                                                        <DataTable
                                                            columns={columns}
                                                            data={listData}
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}

                                                            paginationDefaultPage={currentPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}


                                                        /> :
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    }
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

            <div className="rightbar-overlay"></div>
            <Modal size='lg' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Plan  </h1> </Modal.Header>

                <form onSubmit={handleSubmitForm(onSubmit)}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">Plan Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...registerForm('plan_name', {
                                            required: 'Product Name is required',
                                            pattern: {
                                                value: /^[A-Za-z\s-_]+$/,
                                                message: 'Plan Name can only contain alphabets, spaces, hyphens, and underscores',
                                            }
                                        })}
                                        name="plan_name"
                                    />
                                    {errorsForm.plan_name && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsForm.plan_name.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6  ">
                                <label className="form-label" htmlFor='subscription'>currency <span className='text-danger'>*</span></label>
                                <select className="form-select"  {...registerForm('currency', { required: 'Reason is required' })}  >
                                    <option value="">Select</option>
                                    <option value="USD">USD</option>
                                    <option value="Rupees">Rupees</option>
                                    <option value="CAD">CAD</option>
                                </select>
                                {errorsForm.currency && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.currency.message}</div>)}
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">Amount<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...registerForm('amount', {
                                            required: 'Amount is required',
                                            pattern: {
                                                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                                message: 'Amount must be a valid number'
                                            }
                                        })}
                                        name="amount"
                                    />
                                    {errorsForm.amount && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsForm.amount.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">Total Credits<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...registerForm('total_credits', {
                                            required: 'Total Credits is required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Total Credits must be a valid number'
                                            }
                                        })}
                                        name="total_credits"
                                    />
                                    {errorsForm.total_credits && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsForm.total_credits.message}
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Description <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('description', {
                                        required: 'Description is required',
                                    })} name="description" />
                                    {errorsForm.description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.description.message}</div>}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Subscription Type <span className="text-danger">*</span></label>
                                    <select type="text" className="form-select" {...registerForm('subscription_type', {
                                        required: 'Please select Subscription Type',
                                    })} name="subscription_type" >
                                        <option value="">Select product type</option>
                                        <option value="Trail">Trail</option>
                                        <option value="Paid">Paid</option>
                                    </select>
                                    {errorsForm.subscription_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.subscription_type.message}</div>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Plan Type <span className="text-danger">*</span></label>
                                    <select type="text" className="form-select" {...registerForm('plan_type', {
                                        required: 'Please select plan type',
                                    })} name="plan_type" >
                                        <option value="">Select product type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Quarterly">Quarterly</option>
                                        <option value="Semi-Annually">Semi-Annually</option>
                                        <option value="Annually">Annually</option>
                                    </select>
                                    {errorsForm.product_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.product_type.message}</div>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">No of users <span className="text-danger">*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        {...registerForm('no_of_users', {
                                            required: 'No of users is required',
                                            validate: value => value >= 0 || 'No of users cannot be negative',
                                        })}
                                        name="no_of_users"
                                    />
                                    {errorsForm.no_of_users && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsForm.no_of_users.message}
                                        </div>
                                    )}
                                </div>
                            </div>




                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">No of tests <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" min="0" {...registerForm('no_of_tests', {
                                        required: 'No of tests is required',
                                        validate: value => value >= 0 || 'No of tests cannot be negative',
                                    })} name="no_of_tests" />
                                    {errorsForm.no_of_tests && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.no_of_tests.message}</div>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">No of Resumes <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" min="0" {...registerForm('no_of_resumes', {
                                        required: 'No of resume is required',
                                        validate: value => value >= 0 || 'No of resume cannot be negative',
                                    })} name="no_of_resumes" />
                                    {errorsForm.no_of_resumes && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.no_of_resumes.message}</div>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Cycle Start Day <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" min="0"{...registerForm('cycle_start_day', {
                                        required: 'Cycle Start Day is required',
                                        validate: value => value >= 0 || 'Cycle Start Day cannot be negative',
                                    })} name="cycle_start_day" />
                                    {errorsForm.cycle_start_day && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.cycle_start_day.message}</div>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Cycle End Day<span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" min="0" {...registerForm('cycle_end_day', {
                                        required: 'Cycle End Day is required',
                                        validate: value => value >= 0 || 'Cycle end Day cannot be negative',
                                    })} name="cycle_end_day" />
                                    {errorsForm.cycle_end_day && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.cycle_end_day.message}</div>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Unit Price <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" min="0" {...registerForm('unit_price', {
                                        required: 'Unit Price is required',
                                        validate: value => value >= 0 || 'Unit price cannot be negative',
                                    })} name="unit_price" />
                                    {errorsForm.unit_price && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.unit_price.message}</div>}
                                </div>
                            </div>
                            
                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Preceder Plan  </label>
                                    <select type="text" className="form-select" {...registerForm('preceder_plan')} name="preceder_plan" >
                                        <option value="">Select product type</option>
                                        {listData.map((plan,i)=>{
                                            return (
                                                <option value={plan.id}>{plan.plan_name}</option>
                                            )
                                        })}
                                       
                                        
                                    </select>
                                    {errorsForm.preceder_plan && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.preceder_plan.message}</div>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Perks<span className="text-danger">*</span></label>
                                    {perkslist.map((perk, i)=>{
                                        return (
                                            <div className="positionrelative">
                                            <input type="text" className="form-control mb-2" value={perk.name} onChange={(e)=>changevalueperkslist(e.target.value, i)}   />
                                            <span className="removePerksFromlist" role="button" onClick={()=> removePerksFromlist(i)}>X</span>
                                            </div>
                                        )
                                    })}
                                    <button className='btn btn-primary w-md btn-md ' type="button" onClick={()=>addPerkstoList()}>Add</button>

                                </div>
                            </div>

                        </div>

                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>


            <Modal size='md' show={SuccessAlert} onHide={() => setSuccessAlert(false)} className='text-center  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Plan  </h1> </Modal.Header>
                <ModalBody>
                    <h4>Plan {textbase} Successfully</h4>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className='btn btn-primary w-md btn-md ' id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => setSuccessAlert(false)}  > Ok </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )

}
