
import React, { useEffect, useState } from 'react'
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import PageHeader from '../PageHeader'
import Footer from '../../common/Footer'
import axios from 'axios'
import { downloadDataInCsv,  get_user_menu_permission, triggerAlert } from '../../utils'
import { Table } from 'react-bootstrap'
import Loader from '../../common/Loader'
import { menuIdData } from '../../utils/constants'

export default function BillingInfo() {
    const mainHeading = "Billing Information";
    const heading = "Billing / Billing Information";
    const [isLoading, setIsLoading] = useState(false);
    const [billingInfo, setBillingInfo] = useState([]);
    const [userBillingInfo, setUserBillingInfo] = useState([]);
    const [showUserBillingInfo, setShowUserBillingInfo] = useState(false);
    const [exportPermission, setExportPermission] = useState(false);
    const fetchBillingInfo = async () => {
        setIsLoading(true);
        try {
             let token =sessionStorage.getItem("admin_user_token")
            const response = await axios.get(`apiV1/get-billing-info/`,{
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            const response_data = response.data;
            if (response.status == 200) {
                const itemsArray = response_data.results;
                itemsArray.map((item,i)=>{
                    item.grand_total =+ item.total_amount;
                    return item
                })
                console.log(itemsArray)
                setBillingInfo(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setBillingInfo([]);
                setIsLoading(false);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the information");
            }
        } catch (error) {
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const fetchUserBillingInfo = async (id) => {
        setShowUserBillingInfo(true);
        setIsLoading(true);
        try {
            const response = await axios.get(`apiV1/customer-bil-details/?customer_id=${id}`);
            const response_data = response.data;
            if (response.status == 200) {
                const itemsArray = response_data.results;
                setUserBillingInfo(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setUserBillingInfo([]);
                setIsLoading(false);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the details");
            }
        } catch (error) {
            //console.log(error)
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }

    }

    const exportToExcel = () => {
        // const selectedColumns = ['firstName', 'age'];
        const customHeaders = {
            user_name: 'Username',
            company_name: 'Company Name',
            grand_total_amount: 'Grand Total Bill($)',
            // ... add more custom headers as needed
        };
        downloadDataInCsv(billingInfo.data, 'balance_info.csv', customHeaders, 'grand_total_amount');
    }


    function exportToExcel1(data) {
        // Parse JSON data
        const { data: dataArray, total_amount, plan_tax_amount, grand_total_amount, company_name } = data;

        // Prepare CSV content
        let csvContent = 'Serial Number,Service Name,Service Lines,Service Charge,Total\n';
        dataArray.forEach((item, index) => {
            csvContent += `${index + 1},"${item.service_name}",${item.service_lines},${item.service_charge},$${item.total}\n`;
        });

        // Add rows for total amount, plan tax amount, and grand total amount with dollar symbols
        csvContent += `,,,Total Amount,$${total_amount}\n`;
        csvContent += `,,,Plan Tax Amount,$${plan_tax_amount}\n`;
        csvContent += `,,,Grand Total Amount,$${grand_total_amount}\n`;

        // Convert CSV content to Blob
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;

        // Set download filename with company name and billing info
        a.download = `${company_name}_billing_info.csv`;

        // Append anchor to body and trigger download
        document.body.appendChild(a);
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    // const exportToExcel1 = () => {
    //     // const selectedColumns = ['firstName', 'age'];
    //     const customHeaders = {
    //         service_name: 'Service Name',
    //         service_lines: 'Service Lines',
    //         service_charge: 'Service Charge',
    //         total: 'total',
    //         //grand_total_amount: 'Grand Total Bill($)',
    //         // ... add more custom headers as needed
    //     };
    //     downloadDataInCsv(userBillingInfo.data, 'user_balance_info.csv', customHeaders);
    // }

    useEffect(() => {
        fetchBillingInfo();
        const menu_id = menuIdData?.customer_list;
        // setAddPermission(get_user_menu_permission(menu_id, 'add'));
        // setEditPermission(get_user_menu_permission(menu_id, 'edit'));
        // setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
        setExportPermission(get_user_menu_permission(menu_id, 'export'));
    }, [])


    return (
        <>
            <div id="layout-wrapper">

                <Header />
                <Sidebar />

                {/* <!-- ============================================================== -->
                <!-- Start right Content here -->
                <!-- ============================================================== --> */}
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <PageHeader mainHeading={mainHeading} heading={heading} />

                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {!showUserBillingInfo &&
                                                <>
                                                    <div className="py-2 d-flex justify-content-end">
                                                        {exportPermission &&
                                                            <button className="btn btn-success  px-3" type="button" onClick={exportToExcel}>
                                                                <i className="mdi mdi-file-export-outline" style={{ fontSize: 'large' }}></i> Export to Excel
                                                            </button>
                                                        }
                                                    </div>
                                                    <div className='table-overflow'>
                                                        <Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
                                                            <thead id='thead-overflow' style={{ backgroundColor: '#ededed' }} >
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Username</th>
                                                                    <th>Company Name</th>
                                                                    <th>Grand Total Bill($) </th>
                                                                </tr>
                                                            </thead>

                                                            {isLoading ? (
                                                                <div className='loader-overlay text-white'>
                                                                    <Loader />
                                                                </div>

                                                            ) : (
                                                                <tbody>
                                                                    {billingInfo.length > 0 ? (
                                                                        <>
                                                                            {billingInfo.map((info, index) => (
                                                                                <tr key={info.cust_id}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td id='td-hover'><span onClick={() => fetchUserBillingInfo(info.cust_id)}>{info.user_name ? info.user_name : '-'}</span> </td>
                                                                                    <td>{info.company_name ? info.company_name : '-'}</td>
                                                                                    <td>$ {info.total_amount ? info.total_amount : '0'}</td>
                                                                                </tr>

                                                                            ))}
                                                                            < tr >
                                                                                <td colSpan="3" className="text-end pe-3 py-3">
                                                                                    <p className="mb-2 px-2">Grand Total</p>
                                                                                </td>
                                                                                <td colSpan="2" className="py-3">
                                                                                    <p className='fw-semibold mb-2'>$ { billingInfo.map(item => item.total_amount).reduce((prev, next) => prev + next)}</p>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="4" className="text-center">No data available</td>
                                                                        </tr>
                                                                    )}

                                                                </tbody>
                                                            )}
                                                        </Table >
                                                    </div>
                                                </>
                                            }

                                            {showUserBillingInfo &&
                                                <>
                                                    <div className="py-2 d-flex justify-content-between">
                                                        <h4>{userBillingInfo.company_name} </h4>
                                                        <div>
                                                            {exportPermission &&

                                                                userBillingInfo.data?.length > 0 &&
                                                                <button className="btn btn-success  px-3  me-3" type="button" onClick={() => exportToExcel1(userBillingInfo)}>
                                                                    <i className="mdi mdi-file-export-outline"></i> Export to Excel
                                                                </button>

                                                            }

                                                            <button className="btn btn-warning  px-5" type="button" onClick={() => setShowUserBillingInfo(false)}>
                                                                Back
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
                                                        <thead style={{ backgroundColor: '#ededed' }} >
                                                            <tr>
                                                                <th>S.no</th>
                                                                <th>Service Name</th>
                                                                <th>Service Lines</th>
                                                                <th>Service Charge</th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>

                                                        {isLoading ? (
                                                            <div className='loader-overlay text-white'>
                                                                <Loader />
                                                            </div>

                                                        ) : (
                                                            <tbody>
                                                                {userBillingInfo?.length > 0 ? (
                                                                    <>
                                                                        {userBillingInfo.map((user, index) => (
                                                                            <tr key={user.user_service_id}>
                                                                                <td>{index + 1}</td>
                                                                                <td >{user.subscription_plan__plan_name}</td>
                                                                                <td >{user.subscription_id}</td>
                                                                                <td>$ {user.subscription_type}</td>
                                                                                <td>$ {user.final_price}</td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan="4" className="text-end pe-3 py-3">
                                                                                <p className="mb-2 px-2">Total :</p>
                                                                                <p className="mb-2 px-2">Plan Tax Amount :</p>
                                                                                <p className="mb-2 px-2">Grand Total :</p>
                                                                            </td>
                                                                            <td colSpan="5" className="py-3">
                                                                                <p className='fw-semibold mb-2'>$ {  userBillingInfo.map(item => item.final_price).reduce((prev, next) => prev + next)}</p>
                                                                                <p className='fw-semibold mb-2'>$ {5}</p>
                                                                                <p className='fw-semibold mb-2'>$ {(userBillingInfo.map(item => item.final_price).reduce((prev, next) => prev + next))+5}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5" className="text-center">No data available</td>
                                                                    </tr>
                                                                )}

                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> {/*end col  */}
                            </div> {/*end row  */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}

                    <Footer />
                </div>
                {/* <!-- end main content--> */}
            </div>
            {/* <!-- END layout-wrapper*/}

            {/* <!-- Right bar overlay--> */}
            <div className="rightbar-overlay"></div>
        </>
    )
}
