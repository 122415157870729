import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../../common/Header";
// import Sidebar from "../../../common/Sidebar - Copy";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from '../../../common/Loader';
import { useForm } from 'react-hook-form';
import { getToken, triggerAlert, formattedDateTime, pageReload, sortColumnData, get_user_menu_permission, exportToCsv } from '../../../utils';
import Pagination from "../../PaginationComponent";
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { menuIdData } from '../../../utils/constants';
import DataTable from 'react-data-table-component';

const ManageTickets = () => {

    const apiBaseUrl = process.env.REACT_APP_CUSTURL;
    const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const token = getToken();
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState(null);
    const [selectedTabName, setSelectedTabName] = useState('Manage Tickets');
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [ticketCategory, setTicketCategory] = useState(null);
    const [ticketSubCategory, setTicketSubCategory] = useState(null);
    const [departmentList, setDepartmentList] = useState(null);
    const [showPriority, setShowPriority] = useState(false);
    const [showAssignedTo, setShowAssignedTo] = useState(false);
    const [showCategory, setShowCategory] = useState(false);
    const [showMergeForm, setShowMergeForm] = useState(false);
    const [assignUserList, setAssignUserList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketSummary, setTicketSummary] = useState({});
    const [ticketsList, setticketsList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalitemCount, setTotalitemCount] = useState()
    const [keyword, setKeyword] = useState(null);
    const [tabType, settabType] = useState('OPEN');
    const [color, setColor] = useState('')
    const navigate = useNavigate();
    const [ticketPriorityList, setTicketPriorityList] = useState(null);
    const { register, handleSubmit, formState: { errors }, setValue: setValuePriorityUpdate } = useForm();
    const { register: registerCategory, handleSubmit: handleCatUpdate, formState: { errors: errorsCatUpdate }, setValue: setValueCatUpdate } = useForm();
    const { register: registerAssign, handleSubmit: handleAssignUpdate, formState: { errors: errorsAssignUpdate }, setValue: setValueAssignUpdate, clearErrors: clearErrorsAssign } = useForm();

    const { register: registerMerge, handleSubmit: handleMergeTicket, formState: { errors: errorsMerge }, setValue: setMergeValue, reset: resetMerge } = useForm();

    const mainHeading = selectedTabName;
    const heading = `Support / ${selectedTabName}`;
    // const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;
    // const pageSize = 10;


    const columns = [
        {
            name: 'Ticket Number',
            selector: (row) => row.ticket_number,
            sortable: true,
            sortFunction: (rowA, rowB) => rowA.ticket_number.localeCompare(rowB.ticket_number),
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customer_name,
            sortable: true,
            sortFunction: (rowA, rowB) => rowA.customer_name.localeCompare(rowB.customer_name),
            cell: row => <span>{row.customer_name}</span>,
        },
        {
            name: 'Company',
            selector: (row) => row.company_name,
            sortable: true,
            sortFunction: (rowA, rowB) => rowA.company_name.localeCompare(rowB.company_name),
            cell: row => <span>{row.company_name}</span>,
        },
        {
            name: 'Category',
            selector: (row) => row.ticket_category,
            sortable: true,
            cell: row => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleCategory(row)}>{row.ticket_category}</span>,
        },
        {
            name: 'Sub Category',
            selector: (row) => row.ticket_sub_category,
            sortable: true,
            cell: row => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleCategory(row)}>{row.ticket_sub_category}</span>,
        },
        {
            name: 'Assigned User',
            selector: (row) => row.assigned_user,
            sortable: true,
            cell: row => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleAssignedToVal(row)}>{row.assigned_user}</span>,
        },
        {
            name: 'Created At',
            selector: (row) => row.register_date,
            sortable: true,
            cell: row => formattedDateTime(row.register_date),
        },
        {
            name: 'Updated At',
            selector: (row) => row.updated_date,
            sortable: true,
            cell: row => formattedDateTime(row.updated_date),

        },
        {
            name: 'Created By',
            selector: (row) => row.created_by_name,
            cell: row =>(row.created_by_name),
            sortable: true,
        },
        {
            name: 'Updated By',
            selector: (row) => row.updated_by_name,
            cell: row =>(row.updated_by_name),
            sortable: true,
        },

        {
            name: 'Time Span',
            selector: (row) => row.time_span,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Ticket Priority',
            selector: (row) => row.ticket_priority_value,
            sortable: true,
            cell: row => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handlePriorityVal(row)}>{row.ticket_priority_value}</span>,
        },
        {
            name: 'Status',
            selector: (row) => row.ticket_status_value,
            sortable: true,
            cell: row => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => ticketView(row)}>{row.ticket_status_value === 'Pending' ? 'In progress' : row.ticket_status_value}</span>,
        },
    ];

    const columnStyles = {
        fontSize: '15px',
    };

    columns.forEach(column => {
        column.name = <span style={columnStyles}>{column.name}</span>;
    });



    const fetchTicketListSummary = async () => {
        try {
            let token =sessionStorage.getItem("admin_user_token")
            setIsLoading(true);
            const ticketListSummary = await axios.get(`/apiV1/supports/get_ticket_list_summary/`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const responseStatus = ticketListSummary.status;

            if (responseStatus === 200) {
                const itemsArray = ticketListSummary.data.results.results;
                const totalCount = itemsArray.reduce((total, item) => total + (item.count || 0), 0);
                const updatedItemsArray = [
                    { status__name: 'Total Tickets', count: totalCount },
                    ...itemsArray
                ];
                console.log(updatedItemsArray);
                setTicketSummary(updatedItemsArray);
            } else {
                throw new Error("Something went wrong..");
            }
        } catch (err) {
            triggerAlert("error", err.message);
            setIsLoading(false);
        }
    };
    const handleSort = (field) => {
        //  console.log(sortOrder);
        const sortedData = sortColumnData(sortOrder, ticketsList, field);
        setticketsList(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setSortField(field);
    };


    const fetchTicketsList = async (size = pageSize) => {
        try {
            setIsLoading(true);
            const searchQuery = keyword ? `&keyword=${keyword}` : "";
            // const tabTypeQuery = keyword ? "" : `&tab_type=${tabType}`;
            const ticketsListData = await axios.get(`/apiV1/get_ticket_list/?page_size=${size}&page_number=${pageNum}&tab_type=${tabType}${searchQuery}`);
            const responseStatus = ticketsListData.status;
            if (responseStatus == 200) {
                const itemsArray = ticketsListData.data.results.data;
                setticketsList(itemsArray);
                const total_pages = ticketsListData.data.results.pagination.total_pages;
                setTotalPageCount(total_pages);
                setTotalitemCount(ticketsListData.data.results.pagination.total_items)
                setIsLoading(false);
            } else {
                throw new Error("Something went wrong..");
            }
        } catch (err) {
            //console.log(err.message);
            triggerAlert("error", err.message);
            setIsLoading(false);
        }
    }
    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setPageNum(selectedPage + 1);
    };

    const handleKeySearch = (e) => {
        setPageNum(1);
        setPageSize(10);
        setKeyword(e.target.value);
    }

    const handleTabChange = (e, tabType) => {
        setPageNum(1);
        setPageSize(10)
        settabType(tabType);

    }

    const getColorForStatus = (tabType) => {
        switch (tabType) {
            case 'Open':
                return 'rgb(255, 45, 66)';
            case 'Pending':
                return 'rgb(0, 128, 128)';
            case 'Closed':
                return 'rgb(3, 169, 244)';
            default:
                return 'gray';
        }
    };

    let props = {
        pageCount: totalPageCount,
        handlePageClick: handlePageClick,
        selectedPage: pageNum - 1
    }

    const handlePriorityVal = (ticket) => {
        fetchTicketPriorityList();
        setSelectedTicket(ticket);
        setValuePriorityUpdate('update_priority', ticket.ticket_priority);
        setShowPriority(true);
        setSelectedTabName('Update Ticket Priority');
    };

    const handleAssignedToVal = (ticket) => {
        setSelectedTicket(ticket);
        setShowAssignedTo(true);
        fetchDepartmentList();
        setSelectedTabName('Update Assigned User');
    };

    const handleCategory = async (ticket) => {
        setSelectedTicket(ticket);
        setValueCatUpdate('update_category', ticket.ticket_category_id);
        setValueCatUpdate('update_sub_category', ticket.ticket_sub_category_id);
        const selectedCategoryParentId = ticketCategory.find(category => category.id == ticket.ticket_category_id);
        await fetchTicketSubCategory(selectedCategoryParentId.id, false);
        setShowCategory(true);
        setSelectedTabName('Update Category');
    };

    const handleMerge = () => {
        setShowMergeForm(true);
        setSelectedTabName('Merge Tickets');
    }

    const updateSubCategoryList = (e) => {
        const id = e.target.value;
        const selectedCategoryParentId = ticketCategory.find(category => category.id == id);
        fetchTicketSubCategory(selectedCategoryParentId.id, true);
    }

    const handleCancelbtn = () => {
        setShowPriority(false);
        setShowAssignedTo(false);
        setShowCategory(false);
        setSelectedTabName('Manage Tickets');
        clearErrorsAssign('')
    }

    const fetchTicketPriorityList = async () => {
        try {
            const response = await axios.get(`/apiV1/supports/get_ticket_priority_list`);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                setTicketPriorityList(itemsArray);
            } else if (response.status === 204) {
                setTicketPriorityList([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the ticket priority list.");
            }
        } catch (error) {
            //console.log(error.message);
            triggerAlert("error", "Oops...", "Something went wrong..");

        }
    }

    const fetchTicketCategory = async () => {
        try {
            const response = await axios.get(`/apiV1/get_ticket_parent_category/`);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                setTicketCategory(itemsArray);
            }
            else if (response.status === 204) {
                setTicketCategory([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the ticket category.");
            }
        } catch (error) {
            //console.log(error);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const fetchTicketSubCategory = async (id, status) => {
        try {
            const response = await axios.get(`/apiV1/get_ticket_category?parent_category=${id}`);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                setTicketSubCategory(itemsArray);
                if (status) {
                    setValueCatUpdate('update_sub_category', '');
                }
            }
            else if (response.status === 204) {
                setTicketSubCategory([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the ticket category.");
            }
        } catch (error) {
            //console.log(error);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }


    const handleAssignTo = async (role_id) => {
        try {
            const response = await axios.get(`/apiV1/supports/get_all_admin_users_list?role_id=${role_id}`);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                setAssignUserList(itemsArray);
            } else if (response.status === 204) {
                setAssignUserList([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the assign user list.");
            }
        } catch (error) {
            //console.log(error);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }



    const updateAssigntList = async (e) => {
        try {
            const department_id = e.target.value;
            console.log(department_id);
            clearErrorsAssign('update_department');
            if (department_id) {
                handleAssignTo(department_id);
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const fetchDepartmentList = async () => {
        try {
            const response = await axios.get(`/apiV1/role-dropdown/`);
            const response_data = response.data;
            if (response.status === 200) {
                const itemsArray = response_data
                setDepartmentList(itemsArray);
            }
            else if (response.status === 204) {
                setDepartmentList([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the department list.");
            }
        } catch (error) {
            //console.log(error);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const ticketPrioritySubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'ticket_priority': data.update_priority,
                'ticket_id': selectedTicket.id,
                'user_id': selectedTicket.user_id
            };
            const response = await axios.put(`/apiV1/supports/update_ticket_priority`, params);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Ticket Priority Updated Successfully!!!`);
                // handleCancelbtn();
                // fetchTicketListSummary();
                fetchTicketsList();
                setIsLoading(false);
                pageReload();
            } else {
                throw new Error('Unable to Updated Ticket Successfully!!!');
                setIsLoading(false);
            }
        } catch (err) {
            triggerAlert('error', 'Oops...', err.message);
            setIsLoading(false);
        }
    }

    const ticketCategorySubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'ticket_category_id': data.update_category,
                'ticket_sub_category_id': data.update_sub_category,
                'ticket_id': selectedTicket.id,
                'user_id': selectedTicket.user_id
            };
            const response = await axios.put(`/apiV1/supports/update_ticket_category`, params);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Ticket Category Updated Successfully!!!`);
                // handleCancelbtn();
                // fetchTicketListSummary();
                // fetchTicketsList();
                setIsLoading(false);
                pageReload();
            } else {
                throw new Error('Unable to Updated Ticket Category Successfully!!!');
            }
        } catch (err) {
            triggerAlert('error', 'Oops...', err.message);
            setIsLoading(false);
        }
    }

    const ticketAssignSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'ticket_assignto': data.update_assign_to,
                'ticket_id': selectedTicket.id,
                'user_id': selectedTicket.user_id,
                'escalation_type': data.update_escalation,
                'current_assigned_user': selectedTicket.ticket_assignto
            };
            const response = await axios.put(`/apiV1/supports/update_ticket_assigned_to`, params);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Ticket Assigned User Updated Successfully!!!`);
                // handleCancelbtn();
                // fetchTicketListSummary();
                // fetchTicketsList();
                setIsLoading(false);
                pageReload();
            } else {
                throw new Error('Unable to Updated Ticket Assigned User Successfully!!!');
            }
        } catch (err) {
            triggerAlert('error', 'Oops...', err.message);
            setIsLoading(false);
        }
    }

    const ticketMergeSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'ticket_numbers': data.tickets
            };
            //console.log('params', params);
            // return
            const response = await axios.post(`/apiV1/supports/merge_ticket`, params);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Tickets Merged Successfully!!!`);
                setIsLoading(false);
                pageReload();
            } else {
                throw new Error('Unable to  Tickets Merged Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', err?.response?.data?.message);
        }
    }

    const ticketView = (ticket) => {
        ///console.log(ticket);
        navigate('/support/manage_tickets/view_tickets', { state: ticket });
    }

    useEffect(() => {
        fetchTicketListSummary();
        fetchTicketsList();
        fetchTicketCategory();
        if (selectedTicket) {
            setValueAssignUpdate('update_department', selectedTicket.department_id);
            setValueAssignUpdate('update_assign_to', selectedTicket.ticket_assignto);
            handleAssignTo(selectedTicket.department_id);
        }

    }, [tabType, pageNum, keyword, selectedTicket]);

    const exportToExcel = async () => {
        setIsLoading(true);
        try {
            let apiUrl = `/apiV1/supports/get_tickets_download_data`;
            let params = "";
            if (tabType) params += `?tab_type=${tabType}`;
            if (keyword) params += `&keyword=${keyword}`;

            const response = await axios.get(apiUrl + params, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results;
                exportToCsv(itemsArray, 'tickets');
                setIsLoading(false);
            } else if (response.status === 204) {
                setIsLoading(false);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't export the file");
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    //console.log(ticketsList);
    // Menu Permissions
    const [exportPermission, setExportPermission] = useState(false);
    const [mergePermission, setMergePermission] = useState(false);
    const [changeTicketCatPermission, setChangeTicketCatPermission] = useState(false);
    const [changesAssignedUserPermission, setChangeAssignedUserPermission] = useState(false);
    const [changeTicketPriorityPermission, setChangeTicketPriorityPermission] = useState(false);
    useEffect(() => {
        const menu_id = menuIdData?.manage_tickets;
        const merge_menu_id = menuIdData?.merge_tickets;
        const ticket_cat_menu_id = menuIdData?.change_ticket_category;
        const assigned_user_menu_id = menuIdData?.change_assigned_user;
        const ticket_priority_menu_id = menuIdData?.change_ticket_priority;
        setExportPermission(get_user_menu_permission(menu_id, 'export'));
        setMergePermission(get_user_menu_permission(merge_menu_id, 'child_id'));
        setChangeTicketCatPermission(get_user_menu_permission(ticket_cat_menu_id, 'child_id'));
        setChangeAssignedUserPermission(get_user_menu_permission(assigned_user_menu_id, 'child_id'));
        setChangeTicketPriorityPermission(get_user_menu_permission(ticket_priority_menu_id, 'child_id'));
    }, []);


    const paginationComponentOptions = {

        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handlePerRowsChange = async (page, newPerPage) => {
        fetchTicketsList(page, newPerPage, keyword);
        setPageSize(page);
        setPageNum(newPerPage);
    };
    const handlePageChange = (page) => {
        setPageSize(10)
        setPageNum(page);
    };
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}

                            {!showPriority && !showAssignedTo && !showCategory && !showMergeForm && (
                                <>
                                    <div className="row">
                                        {ticketSummary?.length > 0 ? (
                                            ticketSummary
                                                .slice(0, 4)
                                                .sort((a, b) => {
                                                    const order = {'Total Tickets': 0,'Open': 1,'Pending': 2,'Closed': 3,};
                                                    return order[a.status__name] - order[b.status__name];
                                                })
                                                .map((item, index) => (
                                                    <div key={index} className="col-md-3" onClick={(e) => handleTabChange(e, item.status__name)}>
                                                        <div className="info-tiles pe-5 mb-2" style={{ backgroundColor: getColorForStatus(item.status__name) }}>
                                                            <div className="tiles-heading" data-toggle="tooltip" title={item.status__name === 'Total Tickets' ? 'Total Tickets' : 'Click here'}>
                                                                {item.status__name === 'Pending' ? 'In progress' : (item.status__name ? item.status__name : '-')}
                                                            </div>
                                                            <div className="tiles-body">
                                                                <CountUp start={0} end={item.count ? item.count : 0} duration={5} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                        ) : (
                                            <div>
                                                <p className="text-center">No data available</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className='d-flex justify-content-end'>
                                                            <div className='col-md-3'>
                                                                <input type="text" className="form-control mb-2" id="formrow-email-input" placeholder="Search Ticket number,Username,Company..." onChange={handleKeySearch} />
                                                            </div>
                                                            <div className='col-md-1 ms-2'>
                                                                <button type="button" className="btn btn-primary w-md" onClick={handleMerge}>Merge Tickets</button>
                                                            </div>
                                                            <div className='col-md-1 ms-2'>
                                                                <button type="button" className="btn btn-success w-md" onClick={exportToExcel}>Export to Excel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className=''> */}
                                                    <DataTable
                                                        columns={columns}
                                                        data={ticketsList}
                                                        paginationTotalRows={totalitemCount}
                                                        pagination
                                                        paginationComponentOptions={paginationComponentOptions}
                                                        paginationDefaultPage={pageNum}
                                                        paginationServer
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        fixedHeaderScrollHeight='600px'
                                                        fixedHeader
                                                        persistTableHead
                                                        sortServer
                                                        onSort={(column, sortDirection) => handleSort(column.selector, sortDirection)}
                                                        noDataComponent={<div className="text-center">No data available</div>}
                                                    />
                                                    {/* </div> */}
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {showPriority && (
                                <>
                                    <div className="row  mt-4">
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    <form data-select2-id="21" novalidate="novalidate" onSubmit={handleSubmit(ticketPrioritySubmit)} >
                                                        <div className="row g-3" data-select2-id="20">
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name">Ticket Priority <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" name="update_priority" aria-label="Default select example" {...register('update_priority', { required: 'Ticket Priority is required' })} >
                                                                    <option value="" >Select Ticket Priority</option>
                                                                    {ticketPriorityList && ticketPriorityList.map((priority, index) => (
                                                                        <option key={index} value={priority.priority_id} selected={selectedTicket.ticket_priority == priority.priority_id}>{priority.name}</option>
                                                                    ))}
                                                                </select>
                                                                {errors.update_priority && <p style={{ color: 'red' }}>{errors.update_priority.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 d-flex justify-content-end ">
                                                            <div>
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="submit" className="btn btn-primary w-md">Update</button>
                                                                </div></div>
                                                            <div className="ms-2">
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="button" className="btn btn-warning w-md me-md-2" onClick={handleCancelbtn}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}


                            {showMergeForm && (
                                <>
                                    <div className="row  mt-4">
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    <form data-select2-id="21" novalidate="novalidate" onSubmit={handleMergeTicket(ticketMergeSubmit)} >
                                                        <div className="row g-3" data-select2-id="20">
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name">Ticket Numbers <span style={{ color: 'red' }}>*</span></label>
                                                                <input type="text" name="sub_category" id="formrow-name-input" className="form-control" placeholder="Enter Ticket Number with commas" {...registerMerge('tickets', {
                                                                    required: 'Ticket Numbers is required',
                                                                    pattern: {
                                                                        value: /^[\d,]+$/, // Pattern to allow only numbers and commas
                                                                        message: 'Invalid input. Please enter numbers with commas only.',
                                                                    },
                                                                    validate: {
                                                                        uniqueNumbers: (value) => {
                                                                            const numbers = value.split(',').map(Number); // Convert comma-separated string to an array of numbers
                                                                            const uniqueNumbers = new Set(numbers); // Create a Set to check for uniqueness
                                                                            if (numbers.length !== uniqueNumbers.size) {
                                                                                return 'Ticket numbers cannot be the same.';
                                                                            }
                                                                            return true;
                                                                        }
                                                                    }
                                                                })} />
                                                                {errorsMerge.tickets && <p style={{ color: 'red' }}>{errorsMerge.tickets.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 d-flex justify-content-end ">
                                                            <div>
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="submit" className="btn btn-primary w-md">Merge</button>
                                                                </div>
                                                            </div>
                                                            <div className="ms-2">
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="button" className="btn btn-warning w-md me-md-2" onClick={() => { setShowMergeForm(false); resetMerge() }}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {showCategory && (
                                <>
                                    <div className="row  mt-4">
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    <form data-select2-id="21" novalidate="novalidate" onSubmit={handleCatUpdate(ticketCategorySubmit)} >
                                                        <div className="row g-3" data-select2-id="20">
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name">Ticket Category <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" name="update_category" aria-label="Default select example" {...registerCategory('update_category', { required: 'Ticket Category is required' })} onChange={updateSubCategoryList}>
                                                                    <option value="" hidden>Select Ticket Category</option>
                                                                    {ticketCategory && ticketCategory.map((category, index) => (
                                                                        <option key={index} value={category.id
                                                                        } selected={selectedTicket.ticket_sub_category_id == category.id}>{category.parent_cat_name}</option>
                                                                    ))}
                                                                </select>
                                                                {errorsCatUpdate.update_category && <p style={{ color: 'red' }}>{errorsCatUpdate.update_category.message}</p>}
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name">Ticket Sub Category <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" name="update_sub_category" aria-label="Default select example" {...registerCategory('update_sub_category', { required: 'Ticket Sub Category is required' })}>
                                                                    <option value="" hidden>Select Ticket Sub Category</option>
                                                                    {ticketSubCategory && ticketSubCategory.map((subCategory, index) => (
                                                                        <option key={index} value={subCategory.id
                                                                        } selected={selectedTicket.ticket_sub_category_id == subCategory.ticket_category_id}>
                                                                            {subCategory.ticket_category_name}</option>
                                                                    ))}
                                                                </select>
                                                                {errorsCatUpdate.update_sub_category && <p style={{ color: 'red' }}>{errorsCatUpdate.update_sub_category.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 d-flex justify-content-end ">
                                                            <div>
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="submit" className="btn btn-primary w-md">Update</button>
                                                                </div></div>
                                                            <div className="ms-2">
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="button" className="btn btn-warning w-md me-md-2" onClick={handleCancelbtn}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {showAssignedTo && (
                                <>
                                    <div className="row  mt-4">
                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    <form data-select2-id="21" novalidate="novalidate" onSubmit={handleAssignUpdate(ticketAssignSubmit)} >
                                                        <div className="row g-3" data-select2-id="20">
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name">Department <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" name="update_department" aria-label="Default select example" {...registerAssign('update_department', { required: 'Ticket Department is required' })} onChange={updateAssigntList}>
                                                                    <option value="" >Select Department</option>
                                                                    {departmentList && departmentList.map((department, index) => (
                                                                        <option key={index} value={department.id} selected={selectedTicket.department_id == department.id}>{department.name
                                                                        }</option>
                                                                    ))}
                                                                </select>
                                                                {errorsAssignUpdate.update_department && <p style={{ color: 'red' }}>{errorsAssignUpdate.update_department.message}</p>}
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name">Assign To <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" name="update_assign_to" aria-label="Default select example" {...registerAssign('update_assign_to', { required: 'Assigned User is required' })}>
                                                                    <option value="" >Select Assigned To</option>
                                                                    {assignUserList && assignUserList.map((assignUser, index) => (
                                                                        <option key={index} value={assignUser.id} selected={assignUser.id == selectedTicket.ticket_assignto}>{assignUser.username}</option>
                                                                    ))}
                                                                </select>
                                                                {errorsAssignUpdate.update_assign_to && <p style={{ color: 'red' }}>{errorsAssignUpdate.update_assign_to.message}</p>}
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="formtabs-first-name"> Escalation Process<span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" name="update_escalation" aria-label="Default select example" {...registerAssign('update_escalation', { required: 'Escalation Process is required' })}>
                                                                    <option value="" hidden>Select Escalation Process</option>
                                                                    <option value="Escalated To">Escalated To</option>
                                                                    <option value="Instrumental Help">Instrumental Help</option>
                                                                    <option value="Worked On">Worked On</option>
                                                                    <option value="ReAssigned To">ReAssigned To</option>
                                                                </select>
                                                                {errorsAssignUpdate.update_escalation && <p style={{ color: 'red' }}>{errorsAssignUpdate.update_escalation.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 d-flex justify-content-end ">
                                                            <div>
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="submit" className="btn btn-primary w-md">Update</button>
                                                                </div></div>
                                                            <div className="ms-2">
                                                                <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                <div className="button-items">
                                                                    <button type="button" className="btn btn-warning w-md me-md-2" onClick={handleCancelbtn}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}
            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </div>
    );
}

export default ManageTickets;