import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './pages/Dashboard/Dashboard';
import CustomerList from './pages/Customers/CustomerList';
// import CreateCustomer from './pages/Customers/CreateCustomer';
import ActiveCustomers from './pages/Customers/ActivateCustomers';
// import CreateService from './pages/Customers/CreateService';
import CustomerService from './pages/Customers/CustomerService';
import ManageService from './pages/Customers/ManageService';

import AssignedDids from './pages/dids/AssignedDids';
import UnassignedDids from './pages/dids/UnassignedDids';
import OrderDids from './pages/dids/OrderDids';
import ComingSoon from './pages/ComingSoon';
import Login from './pages/Login/Index';
import RequestStatus from './pages/number_portability/RequestStatus';
import EndPoints from './pages/ip_switches/end_points';
import Invoices from './pages/billing/Invoices';
import InvoiceView from './pages/billing/InvoiceView';
import BalanceHistory from './pages/billing/BalanceHistory';
import BillingInfo from './pages/billing/BillingInfo';
import PaymentDetails from './pages/billing/PaymentDetails';
import TransactionHistory from './pages/billing/TransactionHistory';
import CreateInvoice from './pages/billing/CreateInvoice';
// import CardDetails from './pages/billing/CardDetails';

import ManageTickets from './pages/supports/tickets/manage_tickets';
import CreateTickets from './pages/supports/tickets/create_tickets';
import ViewTickets from './pages/supports/tickets/view_tickets';

import RepliesList from './pages/supports/predefinedreplies/list';
import AddPreDefReplies from './pages/supports/predefinedreplies/add';

import NoteList from './pages/supports/customernotes/list';
import AddNote from './pages/supports/customernotes/add';

import CategoryList from './pages/supports/categories/list';
import AddCategory from './pages/supports/categories/add';

import UserDepartment from './pages/manage_admin/UserDepartment/UserDepartment';
import ManageUserDepartment from './pages/manage_admin/UserDepartment/ManageUserDepartment';

import UserLogs from './pages/manage_admin/UserLogs/UserLogs';

import UserLevel from './pages/manage_admin/UserLevel/UserLevel';
import ManageUserLevel from './pages/manage_admin/UserLevel/ManageUserLevel';

import UserList from './pages/manage_admin/UserList/UserList';
import ManageUserList from './pages/manage_admin/UserList/ManageUserList';
import ChangePassword from './pages/manage_admin/UserList/ChangePassword';
import ChangeLevel from './pages/manage_admin/UserList/ChangeLevel';


import UserMenu from './pages/manage_admin/UserMenu/UserMenu';

import MenuPermission from './pages/manage_admin/MenuPermission/MenuPermission';


import CallHistory from './pages/reports/CallHistory';
import Revenue from './pages/reports/Revenue';

import UserDoc from './pages/others/user_documents/list';
import ManageList from './pages/others/user_documents/manageList';

import Documents from './pages/others/documents/list';
import MngList from './pages/others/documents/manageList';
import HelpDocList from './pages/supports/helpdocuments/list';
import ManageHelpDoc from './pages/supports/helpdocuments/add';


import PrivateRoutes from './common/PrivateRoutes';
import MenuPermission2 from './pages/manage_admin/MenuPermission/MenuPermission2';
import ProductsList from './pages/settings/products';
import PlansList from './pages/settings/plans';
import CustomersListNew from './pages/Customers/Customerslist';
import ManageSupportTickets from './pages/supports/manageSupport';
import TicketCategorieList from './pages/supports/TicketCategorie';
import TicketsubCategorieList from './pages/supports/TicketsubCategorie';
import { useEffect } from 'react';

function App() {

	useEffect(()=>{
		const fetchMenuListApi = async () => {   
			let role_id = sessionStorage.getItem('user_type')
			try { 
				const response      = await axios.get(`apiV1/module-mappings/?role_id=${role_id}`); 
				const response_data = response.data   
				if (response.status === 200) { 
					const itemsArray = response_data.results; 
					sessionStorage.setItem('modulepermissions',  JSON.stringify(itemsArray))
				} 
	
			} catch (error) {  
				// triggerAlert('error', 'Oops...', 'Something went wrong..');
			}
		};
		if(sessionStorage.getItem('user_type')){
			fetchMenuListApi()
		}
		
	}, [])

	return (
		<Router>
			<Routes>
				<Route element={<PrivateRoutes />}>
					<Route exact path="/" element={<Dashboard />} />
					<Route exact path="/dashboard" element={<Dashboard />} />
					<Route exact path="customers/customer_list" element={<CustomerList />} />
					<Route exact path="customers/customerslist" element={<CustomersListNew />} />
					{/* <Route exact path="/customers/create_customer" element={<CreateCustomer />} /> */}
					<Route exact path="/customers/active_customers" element={<ActiveCustomers />} />
					{/* <Route exact path="/customers/create_service" element={<CreateService />} /> */}
					<Route exact path="/customers/customer_service" element={<CustomerService />} />
					<Route exact path="/customers/manage_service" element={<ManageService />} />

					<Route exact path="/dids/assigned_dids" element={<AssignedDids />} />

					<Route exact path="/dids/unassigned_dids" element={<UnassignedDids />} />
					<Route exact path="/dids/order_dids" element={<OrderDids />} />

					<Route exact path="/billing/billing_info" element={<BillingInfo />} />
					<Route exact path="/billing/payment_details" element={<PaymentDetails />} />
					{/* <Route exact path="/billing/card_details" element={<CardDetails />} /> */}
					<Route exact path="/billing/invoice" element={<Invoices />} />
					<Route exact path="/billing/invoice_view" element={<InvoiceView />} />
					<Route exact path="/billing/balance_history" element={<BalanceHistory />} />
					<Route exact path="/billing/transaction_history" element={<TransactionHistory />} />
					<Route exact path="/billing/create_invoice" element={<CreateInvoice />} />


					<Route exact path="/number_portability/request_status" element={<RequestStatus />} />
					<Route exact path="/endpoints/endpoints" element={<EndPoints />} />

					<Route exact path="/support/manage_tickets" element={<ManageTickets />} />
					<Route exact path="/support/managetickets" element={<ManageSupportTickets />} />
					<Route exact path="/support/create_tickets" element={<CreateTickets />} />
					<Route exact path="/support/manage_tickets/view_tickets" element={<ViewTickets />} />

					<Route exact path="/support/predefined_replies" element={<RepliesList />} />
					<Route exact path="/support/predefined_replies/create_predefined_replies" element={<AddPreDefReplies />} />

					<Route exact path="/support/customer_notes" element={<NoteList />} />
					<Route exact path="/support/customer_notes/create_customer_notes" element={<AddNote />} />
					<Route exact path="/support/help_documents" element={<HelpDocList />} />
					<Route exact path="/support/manage_help_documents" element={<ManageHelpDoc />} />

					<Route exact path="/support/categories" element={<CategoryList />} />
					<Route exact path="/support/categorieslist" element={<TicketCategorieList />} />
					<Route exact path="/support/subcategorieslist" element={<TicketsubCategorieList />} />
					<Route exact path="/support/categories" element={<CategoryList />} />
					<Route exact path="/support/create_categories" element={<AddCategory />} />

					<Route exact path="/coming_soon" element={<ComingSoon />} />

					<Route exact path="/manage_admin/user_department" element={<UserDepartment />} />
					<Route exact path="/manage_admin/user_department/manage_user_department" element={<ManageUserDepartment />} />



					<Route exact path="/manage_admin/user_level" element={<UserLevel />} />
					<Route exact path="/manage_user/user_roles" element={<UserLevel />} />
					<Route exact path="/manage_admin/user_level/manage_user_level" element={<ManageUserLevel />} />

					<Route exact path="/manage_admin/user_list" element={<UserList />} />
					<Route exact path="/manage_user/user_list" element={<UserList />} />
					<Route exact path="/settings/products" element={<ProductsList />} />
					<Route exact path="/settings/plans" element={<PlansList />} />
					<Route exact path="/manage_admin/user_list/manage_user_list" element={<ManageUserList />} />
					<Route exact path="/manage_user/user_list/manage_user_list" element={<ManageUserList />} />
					<Route exact path="/manage_admin/user_list/change_level" element={<ChangeLevel />} />
					<Route exact path="/manage_admin/user_list/change_password" element={<ChangePassword />} />

					<Route exact path="/manage_admin/user_menu" element={<UserMenu />} />

					<Route exact path="/manage_admin/menu_permission" element={<MenuPermission />} />
					<Route exact path="/manage_user/access_privileges" element={<MenuPermission2 />} />


					<Route exact path="/reports/call_history" element={<CallHistory />} />
					<Route exact path="/reports/revenue" element={<Revenue />} />
					<Route exact path="/reports/user_logs_list" element={<UserLogs />} />

					<Route exact path="/others/user_documents" element={<UserDoc />} />
					<Route exact path="/others/manage_user_documents" element={<ManageList />} />


					<Route exact path="/others/documents" element={<Documents />} />
					<Route exact path="/others/manage_documents" element={<MngList />} />

				</Route>

				<Route exact path="/login" element={<Login />} />
			</Routes >

		</Router>
	);
}

export default App;
