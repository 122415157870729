import React, { useEffect, useState } from "react";
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../../pages/PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert } from '../../utils';
import Loader from '../../common/Loader'
 
import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm } from 'react-hook-form';
 
export default function TicketsubCategorieList() {
    const mainHeading = "Ticket Sub Categories";
    const heading = "Support / Ticket Sub Categories";
    const [isLoading, setIsLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [SuccessAlert, setSuccessAlert] = useState(false);
    const [CreatenewUser, setCreatenewUser] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
 
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [editid, seteditid] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const [textbase, settextbase] = useState("Create")
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm,  } = useForm( );
    const columns = [
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.Num, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                        <span>{row.Num}</span>
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Sub Category</label>, sortable: true, selector: row => row.parent_cat_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.parent_cat_name}
                    </div>
                </>,
        },
         
        
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Date</label>, sortable: true, selector: row => row.created_at, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.created_at}
                    </div>
                </>,
        },
       
     
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    <div className="btn-group float-end col-md-1  ml-10">
                        <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ "marginLeft": "10px" }}>
                            Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end  ">
                            <div className="dropdown-item hand_icon" onClick={() => { seteditid(row.id); settextbase("Update"); setCreatenewUser(true);  onloadeditedData(row);}}>Edit</div>
                            <div className="dropdown-item hand_icon" onClick={() => DeletePlan(!row.is_deleted, row.id)}  >Delete  </div>
                        </div>
                    </div>
                </>,
        },



    ];
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handleKeySearch = (e) => {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchListData(1, searchkey);
    };
    const onloadeditedData = (data) => {
        setValueForm("plan_name", data.plan_name)
        setValueForm("currency", data.currency)
        setValueForm("amount", data.amount)
        setValueForm("total_credits", data.total_credits)
         
        setValueForm("description", data.description)
        
    }
    const fetchListData = async (page, searchkey) => {
        setIsLoading(true);
        let offcet = parseInt(page) - 1
        try {
            const response = await axios.get(`https://qaadmin.indianhr.in/services/get_ticket_parent_category`);
            const response_data = response.data
            if (response.status === 200) {
                const itemsArray = response_data.results;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item
                })
                // const total_pages = response_data.count;
                // setTotalRows(total_pages);
                setListData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }
            else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }

        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
        }
    };

    const DeletePlan = (status, id) => {
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
             
            try {
                const response = await axios.delete(`apiV1/add-subscription-plan/${id}/`);
                const response_data = response.data;
                if (response_data.status_code === 200) {
                    triggerAlert('success', 'success', 'Status updated Successfully!!');
                    fetchListData(1);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        })

    };
    const onSubmit = (data) => {
        data.plan_level = '1'
        if(editid){
            data.updated_by = sessionStorage.getItem('admin_id')
            axios.patch(`apiV1/add-subscription-plan/${editid}/`, data).then((response) => {
                fetchListData(1);
                setCreatenewUser(false)
                resetForm();
                triggerAlert('success', 'success', 'Data updated successfully');
            })
            .catch((error) => {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            });
        }else{
            data.created_by = sessionStorage.getItem('admin_id')
            axios.post("apiV1/add-subscription-plan/", data).then((response) => {
                fetchListData(1);
                setCreatenewUser(false)
                resetForm();
                triggerAlert('success', 'success', 'Data added successfully');
            })
            .catch((error) => {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            });
        }
        
    };
    const handlePageChange = page => {
        fetchListData(page);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage);
        setPerPage(newPerPage);
    };
    useEffect(() => {
        fetchListData(1);
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">

                                                <div className="btn-group float-end col-md-1  ml-10">
                                                    <button type="button" className="btn btn-primary w-md btn-md" onClick={() => { setCreatenewUser(true); settextbase("Create"); seteditid(); resetForm(); }} style={{ "marginLeft": "10px" }}>
                                                        Create Category
                                                    </button>

                                                </div>

                                            </div>
                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isLoading ?
                                                        <DataTable
                                                            columns={columns}
                                                            data={listData}
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                             
                                                            paginationDefaultPage={currentPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                             

                                                        /> :
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    }
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

            <div className="rightbar-overlay"></div>
            <Modal size='md' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Category  </h1> </Modal.Header>

                <form onSubmit={handleSubmitForm(onSubmit)}>
                    <ModalBody>
                        <div className="row">     
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Name <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('plan_name', { required: 'Product Name is required'})} name="plan_name" />
                                {errorsForm.plan_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.plan_name.message}</div>}
                            </div> 
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Currency<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('currency', { required: 'currency Name is required'})} name="currency" />
                                {errorsForm.currency && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.currency.message}</div>}
                            </div>  
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Amount<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('amount', { required: 'amount is required'})} name="amount" />
                                {errorsForm.amount && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.amount.message}</div>}
                            </div>  
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Total Credits<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('total_credits', { required: 'total_credits is required'})} name="total_credits" />
                                {errorsForm.total_credits && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.total_credits.message}</div>}
                            </div>  
                             
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Description <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('description', {
                                    required: 'Description is required', 
                                })} name="description" />
                                {errorsForm.description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.description.message}</div>}
                            </div>                      
                        </div>
                    


                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>
             

            <Modal size='md' show={SuccessAlert} onHide={() => setSuccessAlert(false)} className='text-center  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Category  </h1> </Modal.Header>
                <ModalBody>
                    <h4>Category {textbase} Successfully</h4>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className='btn btn-primary w-md btn-md ' id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => setSuccessAlert(false)}  > Ok </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )

}
