import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import PageHeader from "../PageHeader";
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { triggerAlert, pageReload, getToken, handleTableRowClick, simpleAlert, ConfirmationAlert, get_user_menu_permission } from '../../utils';
import AutocompleteSearch from "../AutocompleteSearch";
import Loader from '../../common/Loader';
//import { PickAvatarControl } from './PickAvatarControl';
import Table from 'react-bootstrap/Table';
import Pagination from "../PaginationComponent";
import AutoCompanyUserSearch from '../AutoCompanyUserSearch';
import AutoSearchSelect from '../CommonSelectDynamic';
import AutoUserSearch from "../AutoUserSearch";
import { menuIdData } from "../../utils/constants";


function IpRequestStatus() {

	const api_url = process.env.REACT_APP_API_BASE_URL;
	const token = getToken();
	const [count, setCount] = useState(() => {
		const storedCount = sessionStorage.getItem('count');
		return storedCount ? parseInt(storedCount, 10) : 0;
	});
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState([]);
	const [requestData, setRequestData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [pageSlNo, setPageSlNo] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [mgRequest, setMgRequest] = useState(false);
	const [admincustomerId, setAdmincustomerId] = useState(null);
	const [sectionVisible, setSectionVisible] = useState({
		sectionA: true,
		sectionB: false,
	});
	const [selectedTabName, setSelectedTabName] = useState("EndPoints");
	const [showListEndpoint, setShowListEndpoint] = useState(false);
	const handleMgRequestShow = () => setMgRequest(true);
	const handleMgRequestClose = () => {
		setMgRequest(false);
	}
	const [customerId, setcustomerId] = useState(null);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [items, setItems] = useState([]);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const userStateData = useLocation();
	const navigate = useNavigate();
	const mainHeading = selectedTabName;
	const heading = `End Points / ${selectedTabName}`;
	const { register, handleSubmit, formState: { errors }, setValue, control, watch, reset } = useForm();
	const { register: registerAdd, handleSubmit: handleSubmitAdd, formState: { errors: errorsAdd }, setValue: setValueAdd, control: controlAdd, watch: watchAdd, reset: resetAdd } = useForm();

	const periodStart = watch('period_start');
	const [minEndDate, setMinEndDate] = useState(periodStart ? new Date(periodStart) : null);
	const [searchQuery, setSearchQuery] = useState('');
	const [pageLimitSelected, setPageLimitSelected] = useState(10);
	const [isCustomerLoading, setIsCustomerLoading] = useState(false);

	// Menu Permissions
	const [addPermission, setAddPermission] = useState(false);
	// const [editPermission, setEditPermission] = useState(false);
	const [deletePermission, setDeletePermission] = useState(false);
	// const [exportPermission, setExportPermission] = useState(false);


	//console.log(minEndDate);
	const row = userStateData;
	const toggleSection = (section) => {
		setSectionVisible((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};
	const handleOnSelect = (item) => {
		//console.log(item.m4_account_id);
		setcustomerId(item.m4_account_id);
		setAdmincustomerId(item.id)
	};

	const handleOnFocus = () => {
		//console.log("Focused");
		if (!dataLoaded) {
			searchCustomers();
			setDataLoaded(true);
		}

	};

	const handleOnClear = () => {
		//console.log("Cleared");
		setcustomerId(null);
	};

	let autoCompleteProps = {
		items: items,
		fuseOptions: { keys: ["company_name"] },
		placeholder: 'Search user...',
		handleOnSelect: handleOnSelect,
		resultString: "company_name",
		handleOnFocus: handleOnFocus,
		handleOnClear: handleOnClear,
	}

	const handlePageChange = event => {
		setCurrentPage(0);
		if (event.target.value) {
			setPerPageLimit(event.target.value);
			setPageLimitSelected(event.target.value);
		} else {
			setPerPageLimit(10);
			setPageLimitSelected(10);
		}
	};

	const handlePageClick = (selected) => {
		const selectedPage = selected.selected;
		setCurrentPage(selectedPage);
		fetchRequestData(admincustomerId, selectedPage + 1); // Increment the page number by 1 for server-side pagination
	};

	let pgntn_props = {
		pageCount: pageCount,
		handlePageClick: handlePageClick,
		selectedPage: currentPage
	}



	const searchCustomers = async () => {
		try {

			const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
			const response_data = response.data;

			if (response_data.error_code === 200) {
				const itemsArray = response_data.results
				//console.log('response_itemsArray', itemsArray);
				setItems(itemsArray);
			}
			else if (response.status === 204) {
				setItems([]);
			} else {
				triggerAlert('error', 'Oops...', "Couldn't get the customer details");
			}
		} catch (error) {
			triggerAlert("error", "Oops...", "Something went wrong..");
		}
	};

	const fetchRequestData = async (customerId, page) => {


		setIsLoading(true);
		const per_page = perPageLimit;
		try {
			const api_input = {
				page: page,
				per_page: per_page
			};
			if (customerId) {

				const response = await axios.get(api_url + `endpoint/get_endpoints_data?user_id=${customerId}&page_number=${page}&page_size=${per_page}`, token);

				//const response 		= await axios.get(api_url+`list_switches?page=${page}&per_page=${per_page}`);
				const response_data = response.data;
				setPageSlNo((page - 1) * per_page);
				if (response_data.error_code == 200) {
					const itemsArray = response_data.results.data;
					const total_pages = response_data.results.pagination.total_pages;

					setRequestData(itemsArray);
					setPageCount(total_pages);
					setIsLoading(false);
					// const itemsArray = Array.isArray(response_data.results.page.devices.device)
					// if (itemsArray) {
					// 	const items = response_data.results.page.devices.device;
					// 	setData(items);
					// } else {
					// 	const items = [response_data.results.page.devices.device];
					// 	setData(items);
					// }
				} else {
					setPageCount(0);
					setData([]);
					setIsLoading(false);
					triggerAlert('error', 'Oops...', 'Something went wrong..');
				}

				// const page_number 			= response.data.results.pagination.page_number;
				// //console.log("page number is "+page_number);
				// const page_size 			= response.data.results.pagination.page_size;
				// //console.log("page size is "+page_size);
				// const total_pages 			= response.data.results.pagination.total_pages;
				// //console.log("total pages are "+total_pages);
				// const total_items 			= response.data.results.pagination.total_items;
				// //console.log("total items are "+total_items);

				//setPageCount(total_pages);
				setIsLoading(false);
			} else {
				setRequestData([]);
				setIsLoading(false);
			}
		} catch (error) {
			setRequestData([]);
			setIsLoading(false);
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	}

	const formReset = () => {
		reset();
		//handleCurrentDateSearch();
	}

	const handleUserSelect = (userData) => {
		const customer_id = userData ? userData.user_id : null;
		//console.log('primary', userData.user_id);
		setAdmincustomerId(customer_id);
		// fetchRequestData(customer_id,currentPage + 1);

		const user_m4_account_id = userData ? userData.user_m4_account_id : null;
		//console.log(userData.user_m4_account_id);
		setcustomerId(user_m4_account_id);
		fetchRequestData(user_m4_account_id, currentPage + 1);
	};


	const ipSearchSubmit = async (data) => {
		setIsLoading(true);
		const per_page = perPageLimit;
		//console.log(customerId);
		const user_id = customerId;
		if (user_id) {
			try {
				const response = await axios.get(api_url + `endpoint/get_endpoints_data?user_id=` + user_id + '&limit=' + per_page, token);
				//const response 		= await axios.get(api_url+`list_switches?page=${page}&per_page=${per_page}`);
				const response_data = response.data.results;
				// console.log(response_data);    
				setRequestData(response_data);
				setIsLoading(false);
			} catch (error) {
				triggerAlert('error', 'Oops...', 'Something went wrong..');
				setIsLoading(false);
			}
		} else {
			simpleAlert('Search and Select the User!!');
			setIsLoading(false);
		}

	};

	const serviceSubmit = async (data) => {
		//console.log(data);

	};
	const createRequest = () => {
		setSelectedTabName('Create EndPoints');
		setShowListEndpoint(true);
		reset();
	}

	const createEndpoints = async (formData) => {

		setIsLoading(true);
		// console.log('slecthfdhdhgdhsgh',formData);
		if (formData['user_id']) {
			formData['user_id'] = formData['user_id'];
			//formData['user_id'] = 885;
			formData['description'] = formData['description'];
			formData['ipaddr'] = formData['ipaddr'];
			formData['op'] = formData['op'];
			formData['tp'] = formData['tp'];
			formData['u'] = 'admin';
			//console.log(formData);

			try {
				const response = await axios.post(api_url + `endpoint/create_endpoints`, formData, token);
				const response_data = response.data;
				if (response_data.error_code === 200) {
					triggerAlert('success', 'Success', 'End Points Added Successfully');
					setSelectedTabName('End Points');
					setShowListEndpoint(false);
					resetAdd();
					setIsLoading(false);
					setIsCustomerLoading(false);
					fetchRequestData(formData['user_id'], currentPage + 1);
					setValue("user_id", formData['ids']);
					// fetchAssignedDids(currentPage + 1);
				} else {
					fetchRequestData(formData['user_id'], currentPage + 1);
					triggerAlert('error', 'Oops...', "Unable To Add End Ppoint");
					setIsLoading(false);
				}
			} catch (error) {
				console.log(error);
				triggerAlert("error", "Oops...", "Something went wrong..");
				setIsLoading(false);
			}
		} else {
			simpleAlert('Search and Select the User!!');
		}

	}

	const handleCancelbtn = () => {
		setSelectedTabName('Create End Points');
		setShowListEndpoint(false);
		resetAdd();
		pageReload();
		// setPageCount(0);
		// setCurrentPage(0);
		// setPerPageLimit(10);
		// setPageLimitSelected(10);
		// fetchAssignedDids(currentPage + 1);
	}

	useEffect(() => {
		setIsLoading(false);
		setShowListEndpoint(false);

		//console.log(count);
		if (row?.state?.user_id && count == 0) {
			fetchRequestData(row.state.user_id, currentPage + 1);
			setValue('user_id', row.state.ids);
			sessionStorage.setItem('count', "1");
		} else if (row?.state?.user_id && count > 0) {
			const newLocation = { ...row, state: null };
			navigate(newLocation.pathname, { state: null });
			sessionStorage.setItem('count', "0");
		}
	}, [perPageLimit]);

	const deleteRequest = (data) => {
		//console.log("customerId",admincustomerId) 
		if (selectedRowId) {
			const data = {
				// u: "admin",
				cp_id: selectedRowId,
				user_id: admincustomerId

			};


			ConfirmationAlert('You want to continue?', 'Continue', async () => {

				setIsLoading(true);
				////////////////////////////////////////
				try {
					const response = await axios.post(api_url + `endpoint/delete_endpoints`, data, token);
					const response_data = response.data;
					if (response_data.error_code === 200) {
						triggerAlert('success', 'Success', `Connection point deleted successfully`);
						fetchRequestData(customerId, currentPage + 1);
						setValue('user_id', admincustomerId);
						// ipSearchSubmit(currentPage + 1);
						setIsLoading(false);
					} else {
						triggerAlert('error', 'Oops...', 'Failed to delete connection point');
						setIsLoading(false);
					}
				} catch (error) {
					triggerAlert('error', 'Oops...', 'Something went wrong..');
					setIsLoading(false);
				}
			});
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
			setIsLoading(false);
		}
	};


	const settingUser = (selectedOption) => {
		// console.log('selecteduser', selectedOption); 
		setValueAdd('user_id', selectedOption?.m4_account_id ?? null);
	};
	useEffect(() => {
		const menu_id = menuIdData?.endpoints;
		setAddPermission(get_user_menu_permission(menu_id, 'add'));
		// setEditPermission(get_user_menu_permission(menu_id, 'edit'));
		setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
		// setExportPermission(get_user_menu_permission(menu_id, 'export'));
	}, []);

	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />
				{isLoading &&
					<div className='loader-overlay text-white'>
						<Loader />
					</div>
				}
				{/* ============================================================== */}
				{/* Start right Content here */}
				{/* ============================================================== */}
				<div className="main-content">
					<div className="page-content">
						<div hidden={!sectionVisible.sectionA} className="container-fluid">
							{/* start page title */}
							<PageHeader mainHeading={mainHeading} heading={heading} />
							{/* end page title */}
							{!showListEndpoint && (
								<div class="row mt-4 mb-4">
									<div class="col-xl-12">
										<div class="">
											<div class="card-body">
												{/* Tab panes */}
												<form id="searchEndpoint" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container" novalidate="novalidate" onSubmit={handleSubmit(ipSearchSubmit)}>
													<div class="row g-3" data-select2-id="20">
														<div class="col-md-4">
															<label class="form-label" for="formtabs-first-name">User</label>
															{/* <AutocompleteSearch {...autoCompleteProps} /> */}
															{/* <Controller
														name="customer_id"
														{...register('customer_id'
															, { required: 'User is required' }
														)}
														control={control}
														render={({ field }) => (
															<AutoSearchSelect
																// {...field}
																value={field.value}
																apiUrl={api_url_admin + 'dashboard/get_all_customers'}
																onSelect={handleUserSelect}
																placeholder="Search and select user"
																mapOption={result => ({
																	value: result.id,
																	label: result.user_name+', '+result.company_name.substring(0, 15),
																	m4_account_id: result.m4_account_id,
																	mor_account_id:result.mor_account_id
																	// additionalField: result.additionalField,
																	// Add additional fields as needed
																})}
															/>
														)}
													/> */}
															{/* <AutoUserSearch onSelect={handleUserSelect}    /> */}
															<Controller
																control={control}
																name="user_id"
																id="user_id"
																rules={{ required: "User is required" }}
																render={({ field }) => (
																	<AutoUserSearch
																		onSelect={(user) => {
																			//	console.log('heelo', user);
																			setValue("user_id", user ? user.user_m4_account_id : null);
																			setcustomerId(user ? user.user_m4_account_id : null);
																			setAdmincustomerId(user ? user.user_id : null);
																			fetchRequestData(user.user_m4_account_id, 1);
																		}}
																		value={field.value}
																	/>
																)}
															/>
														</div>

														<div class="col-md-2">
															<label class="form-label" for="formtabs-last-name">&nbsp;</label>
															{/* <div class="button-items">
														<button type="submit" class="btn btn-primary w-md">Search</button>
													</div> */}
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							)}
							{!showListEndpoint && (
								<div className="row mb-4">
									<div className="col-12">
										<div className="card">
											{(addPermission || deletePermission) &&
												<div className="card-header">
													{/* <div className="float-end">
											<button type="button" onClick={() => {toggleSection('sectionA');toggleSection('sectionB')}} className="btn btn-primary w-md btn-md">Create Request</button>
										</div> */}

													<div class="btn-group   mb-3 float-end">
														<button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															Action <i class="mdi mdi-chevron-down"></i>
														</button>
														<div class="dropdown-menu dropdown-menu-end py-2">
															{/* <a class="dropdown-item" onClick={() => {toggleSection('sectionA');toggleSection('sectionB')}}>Create Request</a> */}
															{addPermission && <a class="dropdown-item" onClick={createRequest}>Create Request</a>}

															{/* <a class="dropdown-item" onClick={editRequest}><i class="far fa-edit font-size-14"></i> Edit</a> */}
															{deletePermission && <a class="dropdown-item" onClick={deleteRequest}><i class="fas fa-trash-alt font-size-14"></i> Delete  </a>}
														</div>
													</div>

												</div>
											}
											<div className="card-body">

												<Table style={{ width: '100%' }} className="table-responsive table-bordered">
													<thead style={{ backgroundColor: '#ededed' }}>

														<tr>
															<th>#</th>
															<th>Device Type</th>
															<th>Device Description</th>
															<th>Device IP Address</th>
															<th>Device Port</th>
														</tr>
													</thead>
													{isLoading ? (
														// <div className='loader-overlay text-white'>
														// 	<Loader />
														// </div>
														<></>

													) : (
														<tbody>
															{requestData?.length > 0 ? (
																requestData.map((item, index) => (
																	<tr onClick={() => handleTableRowClick(item, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, item.endpoint_id)} className={selectedRowId === item.endpoint_id ? 'row_selected' : ''}  >
																		<td>{pageSlNo + index + 1}</td>
																		<td>{item.device_type}</td>
																		<td>{item.description}</td>
																		<td>{item.ipaddr}</td>
																		<td>{item.port}</td>
																		{/* <td>
																		<div class="dropdown float-end">
																			<a href="#" class="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
																				<i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
																			</a>
																		<div class="dropdown-menu dropdown-menu-end" style={{}}>
																			<a class="dropdown-item" onClick={() => editContact(item)}>Edit</a>
																			<a class="dropdown-item" href="#">Delete</a>
																		</div>
																	</div>
																</td>*/}
																		{/*<td>{item.ip_address}</td>
																<td>{item.name}</td>
																<td>{item.Type}</td>

																<td>
																	{item.status === "Active" ? (
																		<span className="badge bg-success rounded-pill font-size-12">Active</span>
																	) : (
																		<span className="badge bg-danger rounded-pill font-size-12">{item.status}</span>
																	)}
																</td>*/}
																	</tr>
																))
															) : (
																<tr>
																	<td colSpan="8" className="text-center">No data available</td>
																</tr>
															)}

														</tbody>
													)}
												</Table>
												<hr />
												<Pagination {...pgntn_props} />
											</div>
										</div>
									</div> {/* end col */}
								</div>
							)}

							{showListEndpoint && (
								<div className="row  mt-4">
									<div className="col-xl-12">
										<div className="">
											<div className="card-body">

												<form data-select2-id="21" novalidate="novalidate" onSubmit={handleSubmitAdd(createEndpoints)}>
													<div className="row g-3" data-select2-id="20">
														<div className="col-md-3">
															<label className="form-label" htmlFor="formtabs-first-name">Select User <span style={{ color: 'red' }}>*</span></label>
															<Controller control={controlAdd} name="user_id" id="user_id" rules={{ required: "User is required", }} render={({ field }) => (

																// <AutoSearchSelect
																// {...field}
																// apiUrl={process.env.REACT_APP_API_BASE_URL + 'dashboard/get_all_customers'}
																// placeholder="Search Username or Company"
																// mapOption={result => {
																// console.log('sgsdfg', result.m4_account_id);
																// return {
																// 	value: result.m4_account_id,
																// 	label: result.user_name+', '+result.company_name.substring(0, 15),
																// };
																// }}
																// value={field.value}
																// onSelect={settingUser}
																// />

																<AutoUserSearch onSelect={(user) => {
																	if (user.user_m4_account_id) {
																		setValueAdd('user_id', user.user_m4_account_id);
																		setValueAdd('ids', user.user_id);
																	} else {
																		setValueAdd('user_id', null);
																		{ errorsAdd.user_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>M4 account is not there</div> }
																	}
																}} />
															)}
															/>
															{errorsAdd.user_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.user_id.message}</div>}
														</div>

														<div className="col-md-3">
															<label className="form-label" htmlFor="description"> Description <span style={{ color: 'red' }}>*</span></label>
															<div className="input-group input-group-merge has-validation">
																<textarea className="form-control"  {...registerAdd('description', { required: 'Description is required' })} name="description" />
															</div>
															{errorsAdd.description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.description.message}</div>}
														</div>
														<div className="col-md-3">
															<label className="form-label w-100" htmlFor="paymentCard">Enter IP Address <span style={{ color: 'red' }}>*</span></label>
															<div className="input-group input-group-merge has-validation">
																<input name="ipaddr" className="form-control credit-card-mask"
																	type="text" placeholder="1.1.1.1"
																	aria-describedby="paymentCard2" {...registerAdd('ipaddr', {
																		required: 'IP Address is required',
																		pattern: {
																			value: /^(\d{1,3}\.){3}\d{1,3}$/,
																			message: 'Invalid IP address format',
																		},
																	})} />
															</div>
															{errorsAdd.ipaddr && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.ipaddr.message}</div>}
															<div className="fv-plugins-message-container invalid-feedback"></div>
														</div>
														<div className="col-md-3">
															<label className="form-label" htmlFor="paymentCvv"> Origination Point <span style={{ color: 'red' }}>*</span></label>
															<div className="input-group input-group-merge">
																<select className="form-select" aria-label="Default select example" {...registerAdd('op', { required: 'Origination Point is required' })} name="op" >
																	<option value="">Select</option>
																	<option value="1">Yes</option>
																	<option value="0">No</option>
																</select>
															</div>
															{errorsAdd.op && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.op.message}</div>}
														</div>
														<div className="col-md-3">
															<label className="form-label" htmlFor="paymentCvv"> Termination Point <span style={{ color: 'red' }}>*</span></label>
															<div className="input-group input-group-merge">
																<select className="form-select" aria-label="Default select example" {...registerAdd('tp', { required: 'Termination Point is required' })} name="tp" >
																	<option value="">Select</option>
																	<option value="1">Yes</option>
																	<option value="0">No</option>
																</select>
															</div>
															{errorsAdd.tp && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.tp.message}</div>}
														</div>
														<div className="col-md-3 d-flex justify-content-around align-items-center">
															<div>
																<label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
																<div className="button-items">
																	<button type="submit" className="btn btn-primary w-md">Add</button>
																</div></div>
															<div>
																<label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
																<div className="button-items">
																	<button type="button" className="btn btn-warning w-md me-md-2" onClick={handleCancelbtn}>Cancel</button>
																</div></div>

														</div>

													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							)}


						</div> {/* container-fluid */}






					</div>
					{/* End Page-content */}
					<Footer />
				</div>
				{/* end main content*/}
			</div>
			{/* END layout-wrapper */}
			{/* Right bar overlay*/}
			<div className="rightbar-overlay"></div>
		</>
	);
}

export default IpRequestStatus;
