import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../../common/Header";
// import Sidebar from "../../../common/Sidebar - Copy";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from '../../../common/Loader';
import { useForm, Controller } from 'react-hook-form';
import { getToken, triggerAlert, removePTags, handleTableRowClick, pageReload, get_user_menu_permission, ConfirmationAlert } from '../../../utils';
import Pagination from "../../PaginationComponent";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from '../../Editor';
import { menuIdData } from '../../../utils/constants';
import DataTable from 'react-data-table-component';
import { Menu ,MenuItem} from '@mui/material';

const RepliesList = () => {
    const apiBaseUrl = process.env.REACT_APP_CUSTURL;
    const token = getToken();
    const [selectedRow, setSelectedRow] = useState();
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [preRepliesList, setpreRepliesList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [showEditForm, setShowEditForm] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    //const navigate = useNavigate();
    const editorConfiguration = {
        toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'image'],
    };
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();
    const [editorInstance, setEditorInstance] = useState(null);

    const mainHeading = 'Pre Defined Replies';
    const heading = `Support / Pre Defined Replies`;
    // const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;
    const pageSize = 50;
    // Menu Permissions
    const [addPermission, setAddPermission] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [customerlist, setcustomerlist] = useState(false)
    const [selectedidAction, setselectedidAction] = useState('')
    const [selectedidRow, setselectedidRow] = useState({})
    // const [exportPermission, setExportPermission] = useState(false);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event,row) => {
        setAnchorEl(event.currentTarget);
        setselectedidAction(row.predefined_id)
        setselectedidRow(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };






    const columns = [
        {
            name: 'S.No',
            selector: (row, index) =>index+1,
            sortable: true,
        },
        {
            name: 'Subject',
            selector: row => row.predefined_subject,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => removePTags(row.predefined_message),
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.is_deleted,
            sortable: true,
            cell: row => (
                <>
                {/* <div className="btn-group float-end col-md-1 ml-10">
                    <button
                        type="button"
                        className="btn btn-primary w-md btn-md dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ marginLeft: "10px" }}
                    >
                        Action <i className="mdi mdi-chevron-down"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                    {editPermission &&  <div
                            className="dropdown-item hand_icon"
                            onClick={() =>{setSelectedRow(row);handleEditForm(row);}}
                        >
                            Edit
                        </div>}
                       {deletePermission &&
                       <div
                            className="dropdown-item hand_icon"
                            onClick={() => statusChange(row.predefined_id )}
                        >
                            Delete
                        </div>
        }
                    </div>
                </div> */}





{
                            <div>
                                <div
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event)=>handleClick(event,row)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-primary w-md btn-md dropdown-toggle"
                                        // data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ "marginLeft": "10px" }}
                                    >
                                        Action
                                        <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                </div>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        sx: {
                                            boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                            border: '1px solid #e0e0e0', // Custom border color
                                            outline: 'none', // Remove the black border,        
                                            minWidth: '132px'

                                        },
                                        style: {
                                            border: '1px solid #fff', // Ensure border is applied
                                        }
                                    }}
                                >
                                    
                                    {editPermission &&  <MenuItem onClick={() =>{setSelectedRow(selectedidRow);handleEditForm(selectedidRow);}}>
                                            Edit
                                        </MenuItem>}

                                        {deletePermission && <MenuItem onClick={() => {statusChange(selectedidAction)}}>
                                            Delete
                                        </MenuItem>}
                                </Menu>
                            </div>
                            }








                </>
            ),
        }
        
    ];
    const fetchPreDefinedRepliesList = async (pageNo = pageNum,page_size = perPage) => {
        try {
            // setIsLoading(true);
            const ticketsListData = await axios.get (`/apiV1/predefined_replies/list_data?page_size=${page_size}&page_number=${pageNo}`);
            const responseStatus = ticketsListData.status;
            if (responseStatus == 200) {
                const itemsArray = ticketsListData.data.results.data;
                setpreRepliesList(itemsArray);
                const total_items = ticketsListData.data.results.pagination.total_items;
                setTotalPageCount(total_items);
                // setIsLoading(false);
            } else {
                throw new Error("Something went wrong..");
            }
        } catch (err) {
            triggerAlert("error", err.message);
        }
    }


    const deleteReplies = () => {
        if (selectedRowId) {
            //console.log(selectedRowId);
            Swal.fire({
                icon: 'warning',
                title: 'Do you want to delete data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var id = selectedRowId;
                    try {
                        const response = await axios.get(`/apiV1/predefined_replies/delete_data?predefined_id=${id}`);
                        const response_data = response.data;
                        if (response_data.error_code == "200") {
                            triggerAlert('success', 'success', response_data.message);
                            //fetchPreDefinedRepliesList();
                            pageReload();
                        } else {
                            triggerAlert('error', 'Oops...', 'Something went wrong..');
                        }
                    } catch (error) {
                        triggerAlert('error', 'Oops...', error.response.data.message);
                    }
                }
            });
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    };
    const statusChange = (id) => {
        handleClose()
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
            try {
                const response = await axios.get(`/apiV1/predefined_replies/delete_data?predefined_id=${id}`);
                console.log(response);
                const response_data = response.data;
                if (response.status=== 200) {
                    triggerAlert('success', 'success', response_data.message);
                    fetchPreDefinedRepliesList();
                    pageReload();
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', error.response.data.message);
            }
        })

    };

    const formSubmit = async (data) => {
        try {
            // setIsLoading(true);
            const params = {
                'predefined_subject': data.subject,
                'predefined_message': data.editorContent,
                'status': data.type
            };
            const response = await axios.put(`/apiV1/predefined_replies/update_data/${selectedRow.predefined_id}/update`, params);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Pre Defined Replies Updated Successfully!!!`);
                setShowEditForm(false);
                // setIsLoading(false);
                //fetchPreDefinedRepliesList();
                pageReload();
            } else {
                // setIsLoading(false);
                throw new Error('Unable to Updated Pre Defined Replies Successfully!!!');
            }
        } catch (err) {
            // setIsLoading(false);
            triggerAlert('error', 'Oops...', err.message);
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        if (data) {
            clearErrors('editorContent');
        }
        setValue('editorContent', data);
    };

    const handleEditForm =  (selectedRow) => {
        handleClose()
        if (selectedRow) {
            setShowEditForm(true);
            setValue('subject', selectedRow.predefined_subject || '');
            setValue('type', selectedRow.status || '');
            setValue('editorContent', selectedRow.predefined_message || '');
        }
    };

    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setPageNum(selectedPage + 1);
    };

    let props = {
        pageCount: totalPageCount,
        handlePageClick: handlePageClick,
        selectedPage: pageNum - 1
    }

    const handleEditorReady = (editor) => {
        editor.setData(selectedRow?.predefined_message || '');
    };

    // useEffect(() => {
    //     fetchPreDefinedRepliesList();
    //     const menu_id = menuIdData?.predefined_replies;
    //     setAddPermission(get_user_menu_permission(menu_id, 'add'));
    //     setEditPermission(get_user_menu_permission(menu_id, 'edit'));
    //     setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
    //     // setExportPermission(get_user_menu_permission(menu_id, 'export'));
    // }, []);

   
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    console.log(access_privileges,'access_privileges');
    useEffect(() => {
        fetchPreDefinedRepliesList(1)
        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Predefined Replies" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Predefined Replies" && subaccess.permissions.indexOf(2) !== -1) {
                    setAddPermission(true)
                }
                if (subaccess.name == "Predefined Replies" && subaccess.permissions.indexOf(3) !== -1) {
                    setEditPermission(true)
                }
                if (subaccess.name == "Predefined Replies" && subaccess.permissions.indexOf(4) !== -1) {
                    setDeletePermission(true)
                }

            })


        })
    }, []);
    const handlePageChange = page => {
        fetchPreDefinedRepliesList(page);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchPreDefinedRepliesList(page, newPerPage);
        setPerPage(newPerPage);
    };
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {/* {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""} */}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <div className="row mt-5">
                                <div className="col-12">
                                    {!showEditForm ? (
                                        <>
                                            <div className="card">
                                                {/* {(addPermission || editPermission || deletePermission) &&
                                                    <div className="card-header">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-11">
                                                            </div>

                                                            <div class="btn-group   mb-3 float-end col-md-1">
                                                                <button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    Action <i class="mdi mdi-chevron-down"></i>
                                                                </button>
                                                                <div class="dropdown-menu dropdown-menu-end py-2">
                                                                    {addPermission && <Link to="/support/predefined_replies/create_predefined_replies" class="dropdown-item"><i class="bx bx-user"></i> Create </Link>}
                                                                    {editPermission && <Link to="#" onClick={(e) => handleEditForm(e)} class="dropdown-item"><i class="far fa-edit font-size-14"></i> Edit</Link>}
                                                                    {addPermission && <Link to="#" onClick={deleteReplies} class="dropdown-item"><i class="bx bx-trash"></i> Delete</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                } */}

                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-11">
                                                        </div>

                                                        <div class="btn-group   float-end col-md-1">
                                                            {/* <button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                                           {addPermission&& <Link to="/support/predefined_replies/create_predefined_replies" class="btn btn-primary w-md btn-md"><i class="bx bx-user"></i> Create </Link>}
                                                            {/* </button> */}
                                                            {/* <div class="dropdown-menu dropdown-menu-end py-2">
                                                                <Link to="/support/predefined_replies/create_predefined_replies" class="dropdown-item"><i class="bx bx-user"></i> Create </Link>
                                                                <Link to="#" onClick={(e) => handleEditForm(e)} class="dropdown-item"><i class="far fa-edit font-size-14"></i> Edit</Link>
                                                                <Link to="#" onClick={deleteReplies} class="dropdown-item"><i class="bx bx-trash"></i> Delete</Link>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-body'>
                                                {!isLoading ?
                                                    <DataTable
                                                        columns={columns}
                                                        data={preRepliesList}
                                                        pagination
                                                        paginationServer
                                                        fixedHeaderScrollHeight='600px'
                                                        fixedHeader
                                                        persistTableHead
                                                        paginationTotalRows={totalPageCount}
                                                        paginationComponentOptions={paginationComponentOptions}

                                                        paginationDefaultPage={currentPage}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}


                                                    /> :
                                                    <div className='loader-overlay text-white'>
                                                        <Loader />
                                                    </div>
                                                }
                                                </div>
                                                {/* <div className="card-body">
                                                    <table className="table table-bordered dt-responsive  table-responsive  nowrap w-100">
                                                        <thead style={{ backgroundColor: '#ededed' }}>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Subject</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {preRepliesList.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No data available</td>
                                                                </tr>
                                                            ) : (
                                                                preRepliesList.map((list, index) => (
                                                                    <tr onClick={() => handleTableRowClick(list, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, list.predefined_id)} className={selectedRowId === list.predefined_id ? 'row_selected' : ''}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{list.predefined_subject}</td>
                                                                        <td>{removePTags(list.predefined_message)}</td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <hr />
                                                    <Pagination {...props} />
                                                </div> */}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div class="card">
                                                <div class="card-body p-4">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div>
                                                                <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            <div class="mb-3">
                                                                                <label class="form-label" for="formrow-name-input">Subject <span style={{ color: 'red' }}>*</span></label>
                                                                                <input type="text" name="subject" id="formrow-name-input" class="form-control" placeholder="Enter your Subject" {...register('subject', {
                                                                                    required: 'Subject is required',
                                                                                    pattern: {
                                                                                        value: /^[A-Za-z0-9 ]+$/,
                                                                                        message: 'Subject should have only alphabets',
                                                                                    },
                                                                                })} />
                                                                                {errors.subject && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.subject.message}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="mb-3">
                                                                                <label class="form-label" for="formrow-lname-input">Type <span style={{ color: 'red' }}>*</span></label>
                                                                                <select class="form-select" {...register('type', { required: 'Type is required' })} >
                                                                                    <option value="">Please Select</option>
                                                                                    <option value="A">Visible to all</option>
                                                                                    <option value="P">Personal</option>
                                                                                </select>
                                                                                {errors.type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.type.message}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            <label class="form-label" for="formrow-lname-input">Description <span style={{ color: 'red' }}>*</span></label>
                                                                            <Controller
                                                                                name="editorContent"
                                                                                control={control}
                                                                                rules={{ required: 'Content is required' }}
                                                                                render={({ field, fieldState }) => (
                                                                                    <>
                                                                                        <Editor

                                                                                            onChange={field.onChange}
                                                                                            value={field.value}
                                                                                            setContents={field.value} // Add this line to set initial content
                                                                                        />


                                                                                        <div style={{ color: 'red' }}>{fieldState?.error?.message}</div>
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                                        <button class="btn btn-warning w-md" type="button" onClick={() => setShowEditForm(false)}>Cancel</button>
                                                                        <button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}
            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </div>
    );
}

export default RepliesList;